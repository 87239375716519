
import NavBarV2 from "../../NavBarV2";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../../WorkSpace/font.css";
import "../../Workspace2/sidebar.css";
import {
  cities,
  country,
  getTrialBalance,
  signupV2,
  state,
} from "../ApiServices/apiHandler";
import axios from "axios";
import swal from "sweetalert";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../redux/actionTypes";

const SignUp = (props) => {
  const [ShowPwd, setShowPwd] = useState(false);
  const [Cpassword, setCPwd] = useState(false);
  const [allstate, setStateData] = useState([]);
  const [allcountry, setCountryData] = useState([]);
  const [allcities, setCitiesData] = useState([]);
  const dispatch = useDispatch();

  const [error, seterror] = useState({
    org_name: true,
    email: true,
    password: true,
    confirmPwd: true,
  });
  const [Value, setValue] = useState({
    org_name: "",
    email: "",
    password: "",
    confirmPwd: "",
    city: "",
    state: "",
    isAdmin:"Yes",
    approved:"no"
  });
  useEffect(() => {
    validation();
  }, []);
  function validation() {
    let temp = { ...error };
    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,10}[\.][a-z]{2,5}/g;
    temp.org_name =
      Value.org_name | (Value.org_name.length === 0) ? true : false;
    temp.email =
      pattern.test(Value.email) | (Value.email.length === 0) ? true : false;
    temp.password =
      (Value.password.length > 6) | (Value.password.length === 0)
        ? true
        : false;
    temp.confirmPwd =
      (Value.password === Value.confirmPwd) | (Value.confirmPwd.length === 0)
        ? true
        : false;
    seterror(temp);
  }

  const stateData = async () => {
    const res = await state();
    const stateall = res.data.StateData.state.map((data) => data.name);
    setStateData(stateall);
  };
  const countryData = async () => {
    const rescountry = await country();
    const countryall = rescountry.data.CountryData.country.map(
      (data) => data.name
    );
    setCountryData(countryall);
  };
  const citiesData = async () => {
    const rescities = await cities();
    const citiesall = rescities.data.CitiesData.cities.map((data) => data.name);
    setCitiesData(citiesall);
  };

  function handleChange(field, value) {
    setValue({ ...Value, [field]: value });
  }

  const handleSignUp = async () => {
    try {
      if (
      (Value.email.length === 0) |
      (Value.password.length === 0) |
      (Value.confirmPwd.length === 0)
    ) {
      swal("", "fill all the required fields!", "warning");
    } 
    if (Value.password.length < 8) {
      swal("", "Password must be at least 8 characters long!", "warning");
      return;
    }
    
    else {
      let data = {
        org_name: Value.org_name,
        email: Value.email,
        password: Value.password,
        confirmPwd: Value.confirmPwd,
        state: Value.state,
        country: Value.country,
        isAdmin:Value.isAdmin,
        approved:Value.approved
      };

      const result = await signupV2(data);
      if (result.status === 200) {
        props.history.push(`/validation/${result.data.user._id}`);
      } else {
        swal("", result.data.error, "danger");
        console.log("hello")
        
      }
    }
    } catch (error) {
        swal("Error",error.response.data.error,"error")
    }
    
  };

  const onInputChange = (event, value) => {
    if (value && value.length > 3) {
      stateData();
    } else {
      setStateData([]);
    }
  };

  const onInputChangecity = (event, value) => {
    if (value && value.length > 3) {
      citiesData();
    } else {
      setCitiesData([]);
    }
  };

  const responseGoogle = (res) => {
   
    dispatch({
      type: actionTypes.SET_PROFILE_IMAGE,
      payload: res.profileObj.imageUrl,
    });
    dispatch({
      type: actionTypes.SET_PROFILE_EMAIL,
      payload: res.profileObj.email,
    });
    dispatch({
      type: actionTypes.SET_PROFILE_NAME,
      payload: res.profileObj.name,
    });
    const fd = new FormData();
    fd.append("name", res.profileObj.name);
    fd.append("email", res.profileObj.email);
    fd.append("profile_image", res.profileObj.imageUrl);
    fd.append("google_auth_token", res.googleId);
    axios
      .post("/api/v1/auth/gauth-signup", fd)
      .then((res) => {
        localStorage.setItem("auth_token", res.data.token);
        props.history.push("/company");
      })
      .catch((err) => {
        // console.log(err.response)
        // swal(err.response.data.error)
        swal("", err.response.data.error, "error");
        // swal("", err.response.data.error , "error");
      });
  };

  const responseError = (res) => {
    swal("", "hii chinmay", "error");
    // console.log(res,'error response from google')
  };

  return (
    <div className="main_bar">
      <NavBarV2 />
      <div className="main_top pb-5">
        <div className="text-center">
          <h3 className="fs-inter db-v2-head">Sign up now to get started!</h3>
          <p className="dm-sans dbv2-dhead">
            With MyFinalyst, you can easily create and audit financial
            <br />
            statements for your company or clients.
          </p>
        </div>

        <div className="input_box">
          <div className="password-signin">
            <label>Enter your organization name</label>
            <input
              name="org_name"
              placeholder=""
              type="text"
              onChange={(e) => handleChange("org_name", e.target.value)}
            />
          </div>
          <div className="password-signin">
            <label>Enter your organization e-mail address</label>
            <input
              name="email"
              placeholder=""
              type="text"
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>

          <div className="password-signin">
            <label>Set a password for your account</label>
            <input
              id="fname"
              name="password"
              placeholder=""
              type={!ShowPwd ? "password" : "text"}
              onChange={(e) => handleChange("password", e.target.value)}
            />
            <i
              onClick={() => setShowPwd(!ShowPwd)}
              style={{ marginTop: "10px" }}
              className={ShowPwd ? "fas fa-eye" : "far fa-eye-slash"}
            />
          </div>

          <div className="password-signin">
            <label>Confirm your new password</label>
            <input
              id="fname"
              name="confirmPwd"
              placeholder=""
              type={!Cpassword ? "password" : "text"}
              onChange={(e) => handleChange("confirmPwd", e.target.value)}
            />
            <i
              onClick={() => setCPwd(!Cpassword)}
              style={{ marginTop: "10px" }}
              className={Cpassword ? "fas fa-eye" : "far fa-eye-slash"}
            />
          </div>

          <div className="d-flex mt-4 password-signin" style={{ color: "#696F79" }}>
            <label>Select City</label>
            <label style={{ marginLeft: "195px" }}>Select State</label>
          </div>

          <div className="row_box">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // options={allcities?allcities:[]}
              options={!allcities ? [] : allcities}
              onChange={(e, value) => handleChange("city", value, e)}
              onInputChange={onInputChangecity}
              sx={{ width: 350, backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // options={allstate?allstate:[]}
              options={!allstate ? [] : allstate}
              onChange={(e, value) => handleChange("state", value)}
              onInputChange={onInputChange}
              sx={{ width: 350, marginLeft: 5, backgroundColor: "white" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=<LocationOnIcon />
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      border: "none",
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="btn_main">
            <Button className="create_btn" onClick={handleSignUp}>
              Create Account
            </Button>
          </div>
          <div className="split">
            <p>
              <span>or</span>
            </p>
            <div className="clearfix"></div>
          </div>
          <GoogleLogin
            className="google-button"
            buttonText="Sign Up with Google"
            clientId="661989732207-a17ni01sjrqicni6g59bqcv3uhjnannt.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            // onFailure={responseError}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default SignUp;
