import { CommonCalculateTotal, removeHTMLTags } from "../../helper/calculation";
import { dateTimeMapping, dateTimeMappingEPS, dateTimeMappingNotes } from "./dateTimeMap";

export function pyTransformDisclosure48(input, projectDetails = null) {
  let output = [];
  const calculateTotal = (key, dataToCalc) => {
    const copy = [...dataToCalc];
    return copy.reduce((total, item) => total + item[key], 0);
  };
  let labels = {
    particular: {
      value: input.label,
      style: { size: 10, width: 45, bold: true, fill_color: "f2e2b5" },
    },
    py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    cash_flow: { value: null, style: { width: 25, fill_color: "f2e2b5" } },
    others: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
  };
  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    let rowsData = [
      {
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            fill_color: "808080",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cash_flow: {
          value: "As represented in Cash Flow Statement",
          style: {
            size: 10,
            width: 25,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        others: {
          value: "Others #",
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
      },
    ]; // For holding multiple arrays of rows

    // Pushing sub-disclosures
    disclosure.sub_disclosure.forEach((sub) => {
      rowsData.push({
        particular: {
          value: sub.header,
          style: {
            size: 10,
            width: 40,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: sub.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        cash_flow: {
          value: sub.represented_cash_flow,
          style: {
            size: 10,
            width: 25,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        others: {
          value: sub.others,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        cy: {
          value: sub.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      });
    });

    rowsData.push({
      particular: {
        value: "Total",
        style: {
          size: 10,
          width: 40,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "center",
        },
      },
      py: {
        value: calculateTotal('py', disclosure.sub_disclosure),
        style: {
          size: 10,
          width: 15,
          fill_color: "FFFFFF",
          horizontal_align: "right",
          vertical_align: "center",
          bold: true
        },
      },
      cash_flow: {
        value: calculateTotal("represented_cash_flow", disclosure.sub_disclosure),
        style: {
          size: 10,
          width: 25,
          fill_color: "FFFFFF",
          horizontal_align: "right",
          vertical_align: "center",
          bold: true
        },
      },
      others: {
        value: calculateTotal("others", disclosure.sub_disclosure),
        style: {
          size: 10,
          width: 15,
          fill_color: "FFFFFF",
          horizontal_align: "right",
          vertical_align: "center",
          bold: true
        },
      },
      cy: {
        value: calculateTotal("cy", disclosure.sub_disclosure),
        style: {
          size: 10,
          width: 15,
          fill_color: "FFFFFF",
          horizontal_align: "right",
          vertical_align: "center",
          bold: true
        },
      },
    })

    // Pushing data for the current disclosure
    output.push(rowsData);
  });
  output[0].unshift(labels);

  return output;
}

export function pyTransformEPS(input, projectDetails = null) {
  let output = [];

  let labels = {
    particular: {
      value: input.label,
      style: { size: 10, width: 45, bold: true, fill_color: "f2e2b5" },
    },
    cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
    py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
  };

  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    let rowsData = [
      {
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            fill_color: "808080",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            bold: true,
            fill_color: "808080",
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
      },
    ]; // For holding multiple arrays of rows
    // Pushing sub-disclosures
    disclosure.sub_disclosure.forEach((sub) => {
      rowsData.push({
        particular: {
          value: sub.header,
          style: {
            size: 10,
            width: 40,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: sub.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        py: {
          value: sub.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      });
    });

    // Pushing data for the current disclosure
    output.push(rowsData);
  });
  output[0].unshift(labels);
  return output;
}

// Retirement benefit plans
export function pyTransformRBP(input, projectDetails = null) {
  console.log("input", input)
  const calculateTotal = (key, data) => {
    const copy = [...data];
    return copy.reduce((total, item) => {
      const value = item[key];
      if (typeof value === 'number') {
        return total + value;
      }
      return total;
    }, 0);
  };

  let output = [];
  // Iterating over disclosure data
  input.disclosure_data.forEach((disclosure) => {
    const labels = [];
    if (disclosure.isMainHeading) {
      // Pushing main heading
      labels.push({
        particular: {
          value: input.label,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
      labels.push({
        particular: {
          value: disclosure.heading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
    } else if (disclosure.isHeading) {
      // Pushing sub-heading
      labels.push({
        particular: {
          value: removeHTMLTags(disclosure.headerName),
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
      });
    }
    // Pushing sub-disclosure
    let columns = [];
    let rowsData = [];

    disclosure.sub_disclosure.forEach((sub) => {
      let rowData = {
        particular: {
          value: sub.header,
          style: {
            size: 10,
            bold: sub.isHeading ? true : false,
            width: 45,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
            fill_color: "FFFFFF",
          },
        },
      };
      if (sub.gratuity_cy !== undefined) {
        rowData["gratuity_cy"] = {
          value: sub.gratuity_cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.gratuity_py !== undefined) {
        rowData["gratuity_py"] = {
          value: sub.gratuity_py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.compensate_cy !== undefined) {
        rowData["compensate_cy"] = {
          value: sub.compensate_cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.compensate_py !== undefined) {
        rowData["compensate_py"] = {
          value: sub.compensate_py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.dr_inc !== undefined) {
        rowData["dr_inc"] = {
          value: sub.dr_inc,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.dr_dec !== undefined) {
        rowData["dr_dec"] = {
          value: sub.dr_dec,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.er_inc !== undefined) {
        rowData["er_inc"] = {
          value: sub.er_inc,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.er_dec !== undefined) {
        rowData["er_dec"] = {
          value: sub.er_dec,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.cy_allocation !== undefined) {
        rowData["cy_allocation"] = {
          value: sub.cy_allocation,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.py_allocation !== undefined) {
        rowData["py_allocation"] = {
          value: sub.py_allocation,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.cy !== undefined) {
        rowData["cy"] = {
          value: sub.cy,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      if (sub.py !== undefined) {
        rowData["py"] = {
          value: sub.py,
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }
      rowsData.push(rowData);
    });

    if (disclosure.sub_disclosure) {
      let totalRowData = {
        particular: {
          value: "Total",
          style: {
            size: 10,
            bold: true,
            width: 45,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
            fill_color: "FFFFFF",
          },
        },
      };

      if ([1, 11, 12, 10].includes(disclosure?.dis_index_id)) {
        totalRowData["cy"] = {
          value: calculateTotal("cy", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
        if (disclosure?.dis_index_id === 10) {
          totalRowData["cy_allocation"] = {
            value: calculateTotal("cy_allocation", disclosure.sub_disclosure),
            style: {
              size: 10,
              bold: true,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          };
        }
        totalRowData["py"] = {
          value: calculateTotal("py", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };

        if (disclosure?.dis_index_id === 10) {
          totalRowData["py_allocation"] = {
            value: calculateTotal("py_allocation", disclosure.sub_disclosure),
            style: {
              size: 10,
              bold: true,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          };
        }
      }
      if ([2, 3, 4, 5, 6, 7, 8].includes(disclosure?.dis_index_id)) {
        totalRowData["compensate_cy"] = {
          value: calculateTotal("compensate_cy", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
        totalRowData["compensate_py"] = {
          value: calculateTotal("compensate_py", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
        totalRowData["gratuity_cy"] = {
          value: calculateTotal("gratuity_cy", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
        totalRowData["gratuity_py"] = {
          value: calculateTotal("gratuity_py", disclosure.sub_disclosure),
          style: {
            size: 10,
            bold: true,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        };
      }

      rowsData.push(totalRowData)
    }

    // Adding columns based on data presence
    if (disclosure.sub_disclosure[0].gratuity_cy !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: { value: null, style: { fill_color: "f2e2b5" } },
          gratuity_py: { value: null, style: { fill_color: "f2e2b5" } },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          gratuity_py: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: removeHTMLTags(disclosure.headerName),
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          gratuity_cy: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          gratuity_py: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        gratuity_cy: {
          value: "07 February 2024 (Gratuity)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        gratuity_py: {
          value: "07 February 2023 (Gratuity)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_allocation: {
          value: "07 February 2024 (COMPENSATED ABSENCE)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_allocation: {
          value: "07 February 2023 (COMPENSATED ABSENCE)",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else if (disclosure.sub_disclosure[0].dr_inc !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: removeHTMLTags(disclosure.headerName),
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          dr_inc: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          dr_dec: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          er_inc: { value: null, style: { fill_color: "f2e2b5" } },
          er_dec: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        dr_inc: {
          value: "PVO DR +1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        dr_dec: {
          value: "PVO DR -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        er_inc: {
          value: "PVO ER +1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        er_dec: {
          value: "PVO ER -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else if (disclosure.sub_disclosure[0].cy_allocation !== undefined) {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { fill_color: "f2e2b5" } },
          cy_allocation: { value: null, style: { fill_color: "f2e2b5" } },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });

        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          cy_allocation: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: removeHTMLTags(disclosure.headerName),
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          cy_allocation: {
            value: null,
            style: { width: 12, fill_color: "f2e2b5" },
          },
          py: { value: null, style: { fill_color: "f2e2b5" } },
          py_allocation: { value: null, style: { fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: "07 February 2024",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_allocation: {
          value: "PVO DR -1%",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: "07 February 2023",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_allocation: {
          value: "% Allocation",
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    } else {
      if (disclosure.isMainHeading) {
        // Pushing main heading
        columns.push({
          particular: {
            value: input.label,
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
        columns.push({
          particular: {
            value: disclosure.heading,
            style: {
              size: 10,
              bold: true,
              width: 45,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
      } else if (disclosure.isHeading) {
        // Pushing sub-heading
        columns.push({
          particular: {
            value: removeHTMLTags(disclosure.headerName),
            style: {
              size: 10,
              bold: true,
              width: 45,
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
          py: { value: null, style: { width: 12, fill_color: "f2e2b5" } },
        });
      }
      columns.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 45,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 12,
            bold: true,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    // Pushing data for the current disclosure
    rowsData.unshift(columns);
    // Pushing footer if available
    if (disclosure.isFooter) {
      rowsData.push({
        ...columns[0],
        particular: {
          value: removeHTMLTags(disclosure.footer),
          style: { size: 10, width: 45, wrap_text: true, fill_color: "FFFFFF" },
        },
      });
    }
    output.push(rowsData.flat());
  });

  return output;
}

//Related Party Disclosures
export function pyTransformdis51(input, projectDetails) {
  const output = [];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
        py: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
      });
    }

    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      if (item?.dis_index_id === 2) {
        labels.push({
          particular: {
            value: "Nature of Relationship",
            style: {
              size: 10,
              width: 45,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          name_of_related_party: {
            value: "Name of Related Party",
            style: {
              size: 10,
              width: 15,
              fill_color: "808080",
              horizontal_align: "center",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        })
      } else {
        labels.push({
          particular: {
            value: item?.dis_index_id === 3 ? "Nature of Transactions" : item?.dis_index_id === 4 ? "Balance outstanding as at the year end" : item?.dis_index_id === 5 ? "Maximum outstanding during the year" : " Key Management Personnel For year ended",
            style: {
              size: 10,
              width: 45,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: [3, 6].includes(item?.dis_index_id) ? `For year ended ${dateTimeMappingEPS(projectDetails, 'cy')}` : `As at ${dateTimeMappingEPS(projectDetails, 'cy')}`,
            style: {
              size: 10,
              width: 15,
              fill_color: "808080",
              horizontal_align: "center",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: [3, 6].includes(item?.dis_index_id) ? `For year ended ${dateTimeMappingEPS(projectDetails, 'py')}` : `As at ${dateTimeMappingEPS(projectDetails, 'py')}`,
            style: {
              size: 10,
              width: 15,
              fill_color: "808080",
              horizontal_align: "center",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        })
      }
      if (item?.dis_index_id === 2) {
        const data = item.sub_disclosure.map((subItem) => ({
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 45,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          name_related_party: {
            value: subItem.name_related_party,
            style: {
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
        }));
        labels.push(data);
      } else {
        const data = item.sub_disclosure.map((subItem) => ({
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 45,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
        }));

        labels.push(data);
      }
    }
    output.push(labels.flat());
  });

  return output;
}

//Capital Management
export function pyTransformdis52(input, projectDetails) {
  const output = [];
  // [{columns: [{title: input.label}], data: [[{value: ""}]]}];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
        py: { value: null, style: { width: 15, fill_color: "FFFFFF" } },
      });
    }

    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            size: 10,
            bold: true,
            width: 45,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
        py: { value: null, style: { width: 15, fill_color: "f2e2b5" } },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      const columns = {
        particular: {
          value: "Particular",
          style: {
            size: 10,
            width: 45,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 15,
            fill_color: "808080",
            horizontal_align: "center",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            width: 15,
            fill_color: "808080",
            horizontal_align: "center",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      };
      const data = item.sub_disclosure.map((subItem) => ({
        particular: {
          value: subItem.header,
          style: {
            size: 10,
            width: 45,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy: {
          value: subItem.cy,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        py: {
          value: subItem.py,
          style: {
            size: 10,
            width: 15,
            fill_color: "FFFFFF",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
      }));

      labels.push(columns, data);
    }
    output.push(labels.flat());
  });

  return output;
}

//Fair value hierarchy
export function pyTransformdis53(input, projectDetails) {
  const output = [];
  const calculateTotal = (data, key, indexes = null) => {
    return data
      ?.slice(indexes.start, indexes.end)
      .reduce((total, item) => total + (typeof item[key] === "number" ? item[key] : 0), 0);
  }

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
            height: 250,
          },
        },
      });
    }

    if (item.isMainHeading === false && item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }

    if (item.dis_index_id === 2 || item.dis_index_id === 3) {
      if (item.sub_disclosure && item.sub_disclosure.length > 0) {
        const columns = [
          {
            particular: {
              value: "Particulars",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            carrying_value: {
              value: "Carrying Value",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value: {
              value: "Fair Value",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv1: {
              value: "Fair Value (Level 1)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv2: {
              value: "Fair Value (Level 2)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            fair_value_lv3: {
              value: "Fair Value (Level 3)",
              style: {
                size: 10,
                bold: true,
                width: "30",
                fill_color: "808080",
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          },
        ];
        const data = item.sub_disclosure.flatMap((subItem, index, array) => {
          const result = [];

          if (item.dis_index_id === 2 && subItem.sub_dis_index_id === 9) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 2 && subItem.sub_dis_index_id === 12) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 2 && subItem.sub_dis_index_id === 14) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 12, end: 13 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 12, end: 13 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 12, end: 13 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 12, end: 13 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 12, end: 13 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 2 && subItem.sub_dis_index_id === 20) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 14, end: 19 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 14, end: 19 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 14, end: 19 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 14, end: 19 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 14, end: 19 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 2 && subItem.sub_dis_index_id === 22) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 20, end: 21 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 20, end: 21 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 20, end: 21 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 20, end: 21 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 20, end: 21 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 3 && subItem.sub_dis_index_id === 9) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 1, end: 8 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 3 && subItem.sub_dis_index_id === 11) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 9, end: 11 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          if (item.dis_index_id === 3 && subItem.sub_dis_index_id === 17) {
            result.push({
              particular: {
                value: "Total",
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "left",
                  vertical_align: "center",
                  bold: true
                },
              },
              carrying_value: {
                value: calculateTotal(item.sub_disclosure, "carrying_value", { start: 11, end: 16 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value: {
                value: calculateTotal(item.sub_disclosure, "fair_value", { start: 11, end: 16 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv1: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv1", { start: 11, end: 16 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv2: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv2", { start: 11, end: 16 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
              fair_value_lv3: {
                value: calculateTotal(item.sub_disclosure, "fair_value_lv3", { start: 11, end: 16 }),
                style: {
                  size: 10,
                  width: "15",
                  fill_color: "FFFFFF",
                  horizontal_align: "right",
                  vertical_align: "center",
                },
              },
            });
          }

          result.push(
            subItem.isHeading
              ? {
                particular: {
                  value: subItem.header,
                  style: {
                    merge_cells: 6,
                    size: 10,
                    bold: true,
                    width: "30",
                    color: "03565A",
                    fill_color: "f2e2b5",
                    horizontal_align: "left",
                    vertical_align: "top",
                    wrap_text: true,
                  },
                },
              }
              : {
                particular: {
                  value: subItem.header,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "left",
                    vertical_align: "center",
                    wrap_text: true,
                  },
                },
                carrying_value: {
                  value: subItem.carrying_value,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "right",
                    vertical_align: "center",
                  },
                },
                fair_value: {
                  value: subItem.fair_value,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "right",
                    vertical_align: "center",
                  },
                },
                fair_value_lv1: {
                  value: subItem.fair_value_lv1,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "right",
                    vertical_align: "center",
                  },
                },
                fair_value_lv2: {
                  value: subItem.fair_value_lv2,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "right",
                    vertical_align: "center",
                  },
                },
                fair_value_lv3: {
                  value: subItem.fair_value_lv3,
                  style: {
                    size: 10,
                    width: "15",
                    fill_color: "FFFFFF",
                    horizontal_align: "right",
                    vertical_align: "center",
                  },
                },
              }
          );

          return result;
        });

        columns.push(data);
        labels.push(columns.flat());
      }
    }

    if (item.dis_index_id === 4) {
      const labels2 = [
        {
          title1: {
            value: item.heading,
            style: {
              merge_cells: 5,
              width: 30,
              size: 10,
              bold: true,
              color: "03565A",
              fill_color: "f2e2b5",
              horizontal_align: "left",
              vertical_align: "top",
            },
          },
        },
      ];
      if (item.sub_disclosure.length > 0) {
        labels2.push({
          title1: {
            value: "Each class of financial assets/liabilities",
            style: {
              size: 10,
              width: 30,
              bold: true,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          title2: {
            value: "Name of Related Party",
            style: {
              merge_cells: 5,
              size: 10,
              width: 15,
              bold: true,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
        });

        const data = item.sub_disclosure.map((subItem) => ({
          title1: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          title2: {
            value: subItem.name_of_related_party,
            style: {
              merge_cells: 5,
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        }));
        labels2.push(data);
        labels.push(labels2.flat());
      }
    }

    if (item.dis_index_id === 5) {
      const labels3 = [];
      labels3.push({
        title1: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
      labels3.push({
        title1: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 6,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
      labels.push(labels3);
    }
    if (item.dis_index_id === 6) {
      const labels4 = [];
      labels4.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            size: 10,
            bold: true,
            color: "03565A",
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });

      if (item.sub_disclosure.length > 0) {
        labels4.push({
          particular: {
            value: "Nature of Transactions",
            style: {
              size: 10,
              bold: true,
              width: 30,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: dateTimeMappingEPS(projectDetails, 'cy'),
            style: {
              size: 10,
              bold: true,
              width: 15,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: dateTimeMappingEPS(projectDetails, 'py'),
            style: {
              size: 10,
              bold: true,
              width: 15,
              fill_color: "808080",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        });

        const data = item.sub_disclosure.map((subItem) =>
        // [{value: subItem.header}, {value: subItem.cy}, {value: subItem.py}]
        ({
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 15,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
        })
        );
        labels4.push(data);
        labels.push(labels4.flat());
      }
    }
    output.push(labels.flat());
  });

  return output;
}

// Maturity Analysis of Assets and Liabilities
export function pyTransformdis54(input, projectDetails = null) {
  const output = [];
  const calculateTotal = (data, key) => {
    return data.reduce((total, item) => total + (typeof item[key] === "number" ? item[key] : 0), 0);
  }
  // {columns: [{title: input.label}], data: [[{value: ""}]]}
  input.disclosure_data.forEach((item, index) => {
    const labels = [];
    if (index === 0) {
      labels.push({
        particular: {
          value: input.label,
          style: {
            merge_cells: 7,
            size: 10,
            bold: true,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    // if (item.isMainHeading) {
    //   labels.push({
    //     particular: {
    //       value: item.mainHeading,
    //       style: {
    //         merge_cells: 7,
    //         size: 10,
    //         fill_color: "f2e2b5",
    //         bold: true,
    //         horizontal_align: "left",
    //         vertical_align: "center",
    //         wrap_text: true,
    //       },
    //     },
    //   });
    // }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 7,
            size: 10,
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 7,
            height: 50,
            fill_color: "FFFFFF",
            size: 10,
            bold: true,
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: item.dis_index_id === 1 ? "Assets" : "Liabilities",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_within_1_year: {
          value: `Within 1 year ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_after_1_year: {
          value: `After 1 year ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_cy: {
          value: `Total ${dateTimeMappingNotes(projectDetails, 'cy')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_within_1_year: {
          value: `Within 1 year ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_after_1_year: {
          value: `After 1 year ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_py: {
          value: `Total ${dateTimeMappingNotes(projectDetails, 'py')}`,
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        if (subItem.isHeading) {
          return {
            particular: {
              value: subItem?.header,
              style: {
                bold: true,
                size: 10,
                merge_cells: 7,
                horizontal_align: "left",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          };
        } else {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 30,
                fill_color: "FFFFFF",
                horizontal_align: "left",
                vertical_align: "center",
              },
            },
            cy_within_1_year: {
              value: subItem?.cy_within_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            cy_after_1_year: {
              value: subItem?.cy_after_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            total_cy: {
              value: subItem?.total_cy,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            py_within_1_year: {
              value: subItem?.py_within_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            py_after_1_year: {
              value: subItem?.py_after_1_year,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
            total_py: {
              value: subItem?.total_py,
              style: {
                size: 10,
                width: 15,
                fill_color: "FFFFFF",
                horizontal_align: "right",
                vertical_align: "center",
                wrap_text: true,
              },
            },
          };
        }
      });
      data.push({
        particular: {
          value: item.dis_index_id === 1 ? "Total Assets" : "Total Liabilities",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
          },
        },
        cy_within_1_year: {
          value: calculateTotal(item.sub_disclosure, "cy_within_1_year"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_after_1_year: {
          value: calculateTotal(item.sub_disclosure, "cy_after_1_year"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_cy: {
          value: calculateTotal(item.sub_disclosure, "total_cy"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_within_1_year: {
          value: calculateTotal(item.sub_disclosure, "py_within_1_year"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_after_1_year: {
          value: calculateTotal(item.sub_disclosure, "py_after_1_year"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        total_py: {
          value: calculateTotal(item.sub_disclosure, "total_py"),
          style: {
            size: 10,
            bold: true,
            width: 15,
            fill_color: "808080",
            horizontal_align: "right",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      })
      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

// Segment Information
export function pyTransformdis55(input, projectDetails = null) {
  const output = [];

  const GrandTotal = (data, key, indexes) => {
    if (key === "cy") {
      return (CommonCalculateTotal(data, "distribution_cy", { start: indexes?.start, end: indexes?.end }) +
        + CommonCalculateTotal(data, "retail_finance_cy", { start: indexes?.start, end: indexes?.end })
        + CommonCalculateTotal(data, "wholesale_finance_cy", { start: indexes?.start, end: indexes?.end })
      )
    } else {
      return (CommonCalculateTotal(data, "distribution_py", { start: indexes?.start, end: indexes?.end })
        + CommonCalculateTotal(data, "retail_finance_py", { start: indexes?.start, end: indexes?.end })
        + CommonCalculateTotal(data, "wholesale_finance_py", { start: indexes?.start, end: indexes?.end })
      )
    }
  }

  const calculateProfitBeforeTax = (data, key) => GrandTotal(data, key, { start: 0, end: 3 }) + CommonCalculateTotal(data.filter(ele => ele?.sub_dis_index_id === 4 || ele?.sub_dis_index_id === 5), `total_${key}`);
  const calculateNetProfit = (data, key) => calculateProfitBeforeTax(data, key) - CommonCalculateTotal(data.filter(ele => ele?.sub_dis_index_id === 7 || ele?.sub_dis_index_id === 8), `total_${key}`);

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 9,
            size: 10,
            fill_color: "f2e2b5",
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 9,
            size: 10,
            height: 50,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Business Segments",
          style: {
            bold: true,
            size: 10,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_cy: {
          value: `Distribution ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        distribution_py: {
          value: `Distribution ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_cy: {
          value: `Retail Finance ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        retail_finance_py: {
          value: `Retail Finance ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_cy: {
          value: `Wholesale Finance ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        wholesale_finance_py: {
          value: `Wholesale Finance ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_cy: {
          value: `Total ${dateTimeMappingEPS(projectDetails, 'cy')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
        total_py: {
          value: `Total ${dateTimeMappingEPS(projectDetails, 'py')}`,
          style: {
            bold: true,
            size: 10,
            width: 12,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });

      const totalIndices = [4, 13, 15]; // Define indices where 'Total' should be added
      const data = item.sub_disclosure.flatMap((subItem, subDisIndex) => {
        const rows = [];

        // Check if a "Total" row should be added before the current row
        if (totalIndices.includes(subItem.sub_dis_index_id)) {
          rows.push({
            particular: {
              value: subItem.sub_dis_index_id === 4 ? "Results" : subItem.sub_dis_index_id === 13 ? "Total Assets" : "Total Liabilities & Equity",
              style: {
                size: 10,
                width: 30,
                fill_color: "D9D9D9",
                horizontal_align: "left",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            distribution_cy: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "distribution_cy", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "distribution_cy", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "distribution_cy", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            distribution_py: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "distribution_py", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "distribution_py", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "distribution_py", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            retail_finance_cy: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "retail_finance_cy", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "retail_finance_cy", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "retail_finance_cy", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            retail_finance_py: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "retail_finance_py", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "retail_finance_py", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "retail_finance_py", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            wholesale_finance_cy: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_cy", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_cy", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_cy", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            wholesale_finance_py: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_py", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_py", { start: 10, end: 12 }) : CommonCalculateTotal(item.sub_disclosure, "wholesale_finance_py", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            total_cy: {
              value: subItem.sub_dis_index_id === 4 ? GrandTotal(item.sub_disclosure, "cy", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? GrandTotal(item.sub_disclosure, "cy", { start: 10, end: 12 }) : GrandTotal(item.sub_disclosure, "cy", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
            total_py: {
              value: subItem.sub_dis_index_id === 4 ? GrandTotal(item.sub_disclosure, "py", { start: 0, end: 3 }) : subItem.sub_dis_index_id === 13 ? GrandTotal(item.sub_disclosure, "py", { start: 10, end: 12 }) : GrandTotal(item.sub_disclosure, "py", { start: 12, end: 14 }),
              style: {
                size: 10,
                width: 12,
                fill_color: "D9D9D9",
                horizontal_align: "right",
                vertical_align: "top",
                wrap_text: true,
                bold: true
              },
            },
          });
        }

        // Add the current subItem row
        rows.push({
          particular: {
            value: subItem?.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          distribution_cy: {
            value: subItem?.distribution_cy,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          distribution_py: {
            value: subItem?.distribution_py,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          retail_finance_cy: {
            value: subItem?.retail_finance_cy,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          retail_finance_py: {
            value: subItem?.retail_finance_py,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          wholesale_finance_cy: {
            value: subItem?.wholesale_finance_cy,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          wholesale_finance_py: {
            value: subItem?.wholesale_finance_py,
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          total_cy: {
            value: [4, 5, 7, 8].includes(subItem?.sub_dis_index_id) ? subItem?.total_cy :
              subItem?.sub_dis_index_id === 6 ? calculateProfitBeforeTax(item.sub_disclosure, "cy") :
                subItem?.sub_dis_index_id === 9 ? calculateNetProfit(item.sub_disclosure, "cy") :
                  subItem?.sub_dis_index_id === 10 ? null :
                    CommonCalculateTotal(item.sub_disclosure, "dis_55_cy", { type: "type_3", index: subDisIndex }),
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
          total_py: {
            value: [4, 5, 7, 8].includes(subItem?.sub_dis_index_id) ? subItem?.total_py :
              subItem?.sub_dis_index_id === 6 ? calculateProfitBeforeTax(item.sub_disclosure, "py") :
                subItem?.sub_dis_index_id === 9 ? calculateNetProfit(item.sub_disclosure, "py") :
                  subItem?.sub_dis_index_id === 10 ? null :
                    CommonCalculateTotal(item.sub_disclosure, "dis_55_py", { type: "type_3", index: subDisIndex }),
            style: {
              size: 10,
              width: 12,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "top",
              wrap_text: true,
            },
          },
        });

        return rows; // Return the combined rows
      });


      labels.push(data);

      if (item.isFooter) {
        labels.push({
          particular: {
            value: removeHTMLTags(item.footer),
            style: {
              merge_cells: 9,
              size: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "top",
              wrap_text: true,
            },
          },
        });
      }
    }
    labels.push({
      particular: {
        value: null,
        style: {
          size: 10,
          width: 30,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      distribution_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      distribution_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      retail_finance_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      retail_finance_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      wholesale_finance_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      wholesale_finance_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      total_cy: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
      total_py: {
        value: null,
        style: {
          size: 10,
          width: 12,
          fill_color: "FFFFFF",
          horizontal_align: "left",
          vertical_align: "top",
          wrap_text: true,
        },
      },
    });
    output.push(labels.flat());
  });

  return output;
}

// Revenue from contracts with customers
export function pyTransformdis56(input, projectDetails = null) {
  const output = [];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            size: 10,
            fill_color: "f2e2b5",
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
          },
        },
        cy: {
          value: dateTimeMappingEPS(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMappingEPS(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });

      const data = [];
      item.sub_disclosure.forEach((subItem, index) => {
        if ([4, 7, 10].includes(subItem.sub_dis_index_id)) {
          data.push({
            particular: {
              value: "Total",
              style: {
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFF00",
              },
            },
            cy: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "cy", { start: 1, end: 3 }) :
                subItem.sub_dis_index_id === 7 ? CommonCalculateTotal(item.sub_disclosure, "cy", { start: 4, end: 6 }) : CommonCalculateTotal(item.sub_disclosure, "cy", { start: 7, end: 9 })
              ,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFF00",
              },
            },
            py: {
              value: subItem.sub_dis_index_id === 4 ? CommonCalculateTotal(item.sub_disclosure, "py", { start: 1, end: 3 }) :
                subItem.sub_dis_index_id === 7 ? CommonCalculateTotal(item.sub_disclosure, "py", { start: 4, end: 6 }) : CommonCalculateTotal(item.sub_disclosure, "py", { start: 7, end: 9 })
              ,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFF00",
              },
            },
          });
        }

        if (subItem.hasOwnProperty("isHeading") && subItem.isHeading) {
          data.push({
            particular: {
              value: subItem?.header,
              style: {
                merge_cells: 3,
                size: 10,
                bold: true,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          });
        } else {
          data.push({
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 30,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            cy: {
              value: subItem?.cy,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            py: {
              value: subItem?.py,
              style: {
                size: 10,
                width: 15,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          });
        }
      });

      data.push({
        particular: {
          value: "Total",
          style: {
            size: 10,
            bold: true,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "FFFF00",
          },
        },
        cy: {
          value: CommonCalculateTotal(item.sub_disclosure, "cy", { start: 10, end: 12 })
          ,
          style: {
            size: 10,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "FFFF00",
          },
        },
        py: {
          value: CommonCalculateTotal(item.sub_disclosure, "py", { start: 10, end: 12 })
          ,
          style: {
            size: 10,
            width: 15,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "FFFF00",
          },
        },
      })

      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

// Trade & Other Receivables
export function pyTransformdis58(input, projectDetails = null) {
  const output = [];

  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 7, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 7,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
          },
        },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        trade: {
          value: "Trade & Other receivable days past due",
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        zn_days: {
          value: "0-90 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        no_days: {
          value: "91-180 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        ot_days: {
          value: "181-360 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        mt_days: {
          value: "more than 360 days",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        total: {
          value: "Total",
          style: {
            size: 10,
            width: 12,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
      });
      const data = item.sub_disclosure.map((subItem, subDisIndex) => {
        return {
          particular: {
            value:
              subItem.hasOwnProperty("table") && subItem?.table === "cy"
                ? dateTimeMapping(projectDetails, 'cy')
                : subItem?.table === "py"
                  ? dateTimeMapping(projectDetails, 'py')
                  : "ECL Rate",
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              bold: !subItem.hasOwnProperty("table") ? true : false,
              fill_color: "FFFFFF",
            },
          },
          trade: {
            value: subItem?.header,
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          zn_days: {
            value: subItem["0_90_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          no_days: {
            value: subItem["91_180_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          ot_days: {
            value: subItem["181_360_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          mt_days: {
            value: subItem["more_than_360_days"],
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total: {
            value: CommonCalculateTotal(item.sub_disclosure, "dis_58", { type: "type_3", index: subDisIndex }),
            style: {
              size: 10,
              width: 12,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
      output.push(labels.flat());
    }
  });

  return output;
}

//Accounting for Employee Share based Payments
export function pyTransformdis59(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 3, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 3,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            // color: "03565A",
            fill_color: [3, 4, 5, 6].includes(item?.dis_index_id) ? "f2e2b5" : "FFFFFF",
            // bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }

    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        option: {
          value: "Options",
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        weighted_avg: {
          value: "Weighted Average Exercise Price",
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem?.header,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          option: {
            value: subItem?.options,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          weighted_avg: {
            value: subItem?.weighted_average,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer) || item.footer,
          style: { merge_cells: 3, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      options: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
      weighted_avg: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
    });
    output.push(labels.flat());
  });
  return output;
}

//Leases
export function pyTransformdis60(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 3, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 3,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            width: 40,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            width: 20,
            horizontal_align: "left",
            vertical_align: "center",
            bold: true,
            fill_color: "808080",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem?.header,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem?.cy,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          py: {
            value: subItem?.py,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer) || item.footer,
          style: { merge_cells: 3, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      cy: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
      py: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
    });
    output.push(labels.flat());
  });
  return output;
}

//Disclosures as required under Master Direction - Non-Banking Financial Company -Systemically Important Non-Deposit taking Company and Deposit taking Company (ReserveBank) Directions, 2016
export function pyTransformdis61(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 3, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName) || item.headerName,
          style: {
            merge_cells: 3,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 3,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      if ([3, 4, 5, 7, 8, 12, 13, 14, 15, 16, 21, 23, 33, 34].includes(item.dis_index_id)) {
        labels.push({
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          cy: {
            value: dateTimeMapping(projectDetails, 'cy'),
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          py: {
            value: dateTimeMapping(projectDetails, 'py'),
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([9].includes(item.dis_index_id)) {
        labels.push({
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          weighted_avrg_exercise_price: {
            value: "Weighted Average Exercise Price",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([26].includes(item.dis_index_id)) {
        labels.push({
          regulator: {
            value: "Regulator",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          registration_no: {
            value: "Registration No.",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([27].includes(item.dis_index_id)) {
        labels.push({
          sr_no: {
            value: "Sr.No.",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          name_of_the_instrument: {
            value: "Name of the Instrument",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          cy_header: {
            value: `For the year ended ${dateTimeMapping(projectDetails, 'cy')}`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          py_header: {
            value: `For the year ended ${dateTimeMapping(projectDetails, 'py')}`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([10, 11].includes(item.dis_index_id)) {
        labels.push({
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          "1_to_7days": {
            value: "1 to 7 days",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "8_to_14days": {
            value: `1 to 14 days`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "15_to_30days": {
            value: `1 to 30/31 days`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_1month": {
            value: `Over 1 month & upto 2 months`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_2months": {
            value: `Over 2 months & upto 3 months`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_3months": {
            value: `Over 3 months & upto 6 months`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_6months": {
            value: `Over 6 months & upto 1 year`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_1year": {
            value: `Over 1 year & upto 3 years `,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_3years": {
            value: `Over 3 years & upto 5 years`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          "over_5years": {
            value: `Over 5 years`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total: {
            value: `Total`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([35, 36, 37, 39, 40, 41].includes(item.dis_index_id)) {
        labels.push({
          sr_no: {
            value: "Sr.No.",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          particular: {
            value: "Significant counterparty*",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          amount: {
            value: "Amount",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_liabilities: {
            value: `% of total liabilities`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([24, 25].includes(item.dis_index_id)) {
        labels.push({
          sr_no: {
            value: "Sr.No.",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          particular: {
            value: "Grounds of complaints, (i.e. complaints relating to)",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          pending_at_the_beginning_of_the_year: {
            value: "Number of complaints pending at the beginning of the year",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          received_during_the_year: {
            value: `Number of complaints received during the year`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          received_over_the_previous_year: {
            value: `% increase/ decrease in the number of complaints received over the previous year`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          pending_at_the_end_of_the_year: {
            value: `Number of complaints pending at the end of the year`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          pending_beyond_30_days: {
            value: `Of 5, number of complaints pending beyond 30 days`,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }
      if ([38, 42].includes(item.dis_index_id)) {
        labels.push({
          sr_no: {
            value: "Sr.No.",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          particular: {
            value: "Particular",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          percentage: {
            value: "%",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          reference: {
            value: "References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          ind_as_reference: {
            value: "IND AS References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          checklist_reference: {
            value: "Checklist References",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        });
      }


      if ([3, 4, 5, 7, 8, 12, 13, 14, 15, 16, 21, 23, 33, 34].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            cy: {
              value: subItem?.cy,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            py: {
              value: subItem?.py,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([9].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            weighted_avrg_exercise_price: {
              value: subItem?.weighted_avrg_exercise_price,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([26].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            regulator: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            registration_no: {
              value: subItem?.header_2,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([27].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            sr_no: {
              value: subItem?.sr_no,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            name_of_the_instrument: {
              value: subItem?.header_2,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            cy_header: {
              value: subItem?.cy_header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            py_header: {
              value: subItem?.py_header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([10, 11].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem, subDisIndex) => {
          return {
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "1_to_7days": {
              value: subItem["1_to_7days"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "8_to_14days": {
              value: subItem["8_to_14days"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "15_to_30days": {
              value: subItem["15_to_30days"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_1month": {
              value: subItem["over_1month"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_2months": {
              value: subItem["over_2months"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_3months": {
              value: subItem["over_3months"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_6months": {
              value: subItem["over_6months"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_1year": {
              value: subItem["over_1year"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_3years": {
              value: subItem["over_3years"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "over_5years": {
              value: subItem["over_5years"],
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            "total": {
              value: CommonCalculateTotal(item.sub_disclosure, null, { type: "type_3", index: subDisIndex }),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([35, 36, 37, 39, 40, 41].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            sr_no: {
              value: subItem?.sr_no,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            amount: {
              value: subItem?.amount,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            total_liabilities: {
              value: subItem?.total_liabilities,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([24, 25].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            sr_no: {
              value: subItem?.sr_no,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            pending_at_the_beginning_of_the_year: {
              value: subItem?.pending_at_the_beginning_of_the_year,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            received_during_the_year: {
              value: subItem?.received_during_the_year,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            received_over_the_previous_year: {
              value: subItem?.received_over_the_previous_year,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            pending_at_the_end_of_the_year: {
              value: subItem?.pending_at_the_end_of_the_year,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            pending_beyond_30_days: {
              value: subItem?.pending_beyond_30_days,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }
      if ([38, 42].includes(item.dis_index_id)) {
        const data = item.sub_disclosure.map((subItem) => {
          return {
            sr_no: {
              value: subItem?.sr_no,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            particular: {
              value: subItem?.header,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            percentage: {
              value: subItem?.percentage,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            reference: {
              value: subItem?.refData
                .map(ele => ele?.reference)
                .filter(reference => reference)
                .join(', '),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            ind_as_reference: {
              value: subItem?.isReference === "ind_as_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
            checklist_reference: {
              value: subItem?.isReference === "checklist_reference" ? "yes" : "no",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "right",
                vertical_align: "center",
                fill_color: "FFFFFF",
              },
            },
          };
        });
        labels.push(data);
      }

    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer) || item.footer,
          style: { merge_cells: 3, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      cy: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
      py: { value: null, style: { width: 20, fill_color: "FFFFFF" } },
    });
    output.push(labels.flat());
  });
  return output;
}

//"Disclosure in compliance with RBI circular 2020-21/17 DOR.No.BP.BC/4/21.04.048/2020-21"
export function pyTransformdis62(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      if (item.sub_disclosure[0].hasOwnProperty("no_of_accounts")) {
        labels.push({
          particular: {
            value: "As At",
            style: {
              size: 10,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          acc_restrict: {
            value: "No. of accounts restructured",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          amt_outstanding: {
            value: "Amount outstanding",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          test: { value: null, style: { fill_color: "808080" } },
          test2: { value: null, style: { fill_color: "808080" } },
          test3: { value: null, style: { fill_color: "808080" } },
        });
      } else if (
        item.sub_disclosure[0].hasOwnProperty("npa_during_the_half_year")
      ) {
        labels.push(
          {
            particular: {
              value: "Format-B",
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            exposure_acc: {
              value: null,
              style: {
                size: 10,
                width: 25,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            aggregate_debt: {
              value: null,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_written: {
              value: null,
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            amount_paid: {
              value: null,
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            exposure_classified: {
              value: dateTimeMapping(projectDetails, 'cy'),
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
          },
          {
            particular: {
              value: "Type of borrower",
              style: {
                size: 10,
                width: 40,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
              },
            },
            exposure_acc: {
              value:
                "Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of the previous half-year (A)",
              style: {
                size: 10,
                width: 25,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            aggregate_debt: {
              value:
                "Of (A), aggregate debt that slipped into NPA during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_written: {
              value: "Of (A) amount written off during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            amount_paid: {
              value: "Of (A) amount paid by the borrowers during the half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
            exposure_classified: {
              value:
                "Exposure to accounts classified as Standard consequent to implementation of resolution plan – Position as at the end of this half-year",
              style: {
                size: 10,
                width: 20,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "808080",
                wrap_text: true,
              },
            },
          }
        );
      }

      const data = item.sub_disclosure.map((subItem) => {
        if (subItem.hasOwnProperty("no_of_accounts")) {
          return {
            particular: {
              value: "As At",
              style: {
                size: 10,
                horizontal_align: "left",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
              },
            },
            acc_restrict: {
              value: subItem?.no_of_accounts,
              style: {
                size: 10,
                horizontal_align: "right",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            amt_outstanding: {
              value: subItem?.amount,
              style: {
                size: 10,
                horizontal_align: "right",
                vertical_align: "center",
                bold: true,
                fill_color: "FFFFFF",
                wrap_text: true,
              },
            },
            test: { value: null, style: { fill_color: "FFFFFF" } },
            test2: { value: null, style: { fill_color: "FFFFFF" } },
            test3: { value: null, style: { fill_color: "FFFFFF" } },
          };
        }
        return {
          particular: {
            value: subItem?.header || null,
            style: {
              size: 10,
              width: 30,
              horizontal_align: "left",
              vertical_align: "center",
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          exposure_acc: {
            value: subItem?.position_end_previous_half_year || null,
            style: {
              size: 10,
              width: 25,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          aggregate_debt: {
            value: subItem?.npa_during_the_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          amount_written: {
            value: subItem?.amount_written_during_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          amount_paid: {
            value: subItem?.amount_paid_during_half_year || null,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          exposure_classified: {
            value: subItem?.position_end_this_half_year || null,
            style: {
              size: 10,
              width: 25,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.footer.length > 200 ? 150 : 80,
            size: 10,
            fill_color: "FFFFFF",
            horizontal_align: "left",
            vertical_align: "top",
            wrap_text: true,
          },
        },
      });
    }
    if (!item.sub_disclosure[0].hasOwnProperty("no_of_accounts")) {
      labels.push({
        particular: {
          value: null,
          style: {
            size: 10,
            width: 30,
            horizontal_align: "left",
            vertical_align: "center",
            fill_color: "FFFFFF",
            wrap_text: true,
          },
        },
        exposure_acc: {
          value: null,
          style: {
            size: 10,
            width: 25,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        aggregate_debt: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        amount_written: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        amount_paid: {
          value: null,
          style: {
            size: 10,
            width: 20,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
        exposure_classified: {
          value: null,
          style: {
            size: 10,
            width: 25,
            horizontal_align: "right",
            vertical_align: "center",
            fill_color: "FFFFFF",
          },
        },
      });
    }

    output.push(labels.flat());
  });
  return output;
}

// disclosure63
export function pyTransformdis63(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

//LCR Disclosure
export function pyTransformdis64(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 5, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 5,
            height: item.headerName.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 150 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push(
        // {
        //     particular: {value: 'Particulars', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080'}},
        //     cy: {value: 'Total Unweighted Value(average)', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080', wrap_text: true}},
        //     py: {value: 'Total Weighted Value (average)', style: {size: 10, horizontal_align: 'left', vertical_align: 'center', bold: true, fill_color: '808080', wrap_text: true}},
        // },
        {
          particular: {
            value: "Particulars",
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
            },
          },
          total_unweighted_cy: {
            value: "Total Unweighted Value(average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_weighted_cy: {
            value: "Total Weighted Value (average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_unweighted_py: {
            value: "Total Unweighted Value(average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
          total_weighted_py: {
            value: "Total Weighted Value (average)",
            style: {
              size: 10,
              width: 20,
              horizontal_align: "left",
              vertical_align: "center",
              bold: true,
              fill_color: "808080",
              wrap_text: true,
            },
          },
        }
      );
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 40,
              horizontal_align: "left",
              vertical_align: "center",
              bold: subItem.isHeading ? true : false,
              fill_color: "FFFFFF",
              wrap_text: true,
            },
          },
          total_unweighted_cy: {
            value: subItem?.cy_total_unweighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_weighted_cy: {
            value: subItem?.cy_total_weighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_unweighted_py: {
            value: subItem?.py_total_unweighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
          total_weighted_py: {
            value: subItem?.py_total_weighted,
            style: {
              size: 10,
              width: 20,
              horizontal_align: "right",
              vertical_align: "center",
              fill_color: "FFFFFF",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: { merge_cells: 5, size: 10, fill_color: "FFFFFF" },
        },
      });
    }
    labels.push({
      particular: { value: null, style: { width: 40, fill_color: "FFFFFF" } },
      total_unweighted_cy: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_weighted_cy: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_unweighted_py: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
      total_weighted_py: {
        value: null,
        style: { width: 20, fill_color: "FFFFFF" },
      },
    });
    output.push(labels.flat());
  });
  return output;
}

// disclosure65
export function pyTransformdis65(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure66
export function pyTransformdis66(input) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 5, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 5,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        particular: {
          value: "Nature of Assets",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_sold: {
          value: "CY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_associate: {
          value: "CY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_sold: {
          value: "PY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_associate: {
          value: "PY Carrying amount of sold assets",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_sold: {
            value: subItem.cy_carrying_amount_sold,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_associate: {
            value: subItem.cy_carrying_amount_associated,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          py_sold: {
            value: subItem.py_carrying_amount_sold,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          py_associate: {
            value: subItem.py_carrying_amount_associated,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 5,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure67
export function pyTransformdis67(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure68
export function pyTransformdis68(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

//disclosure69
export function pyTransformdis69(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: {
            merge_cells: 10,
            size: 10,
            fill_color: "f2e2b5",
            bold: true,
          },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 10,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 10,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        ratio: {
          value: "Ratios",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_numerator: {
          value: "CY Numerator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy_denominator: {
          value: "CY Denomirator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        cy_ratio: {
          value: "CY Ratio",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_numerator: {
          value: "PY Numerator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_denominator: {
          value: "PY Denomirator",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py_ratio: {
          value: "PY Ratio",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        variance: {
          value: "Variance",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        reason_for_variance: {
          value: "Reason for Variance (if above 25%)",
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          ratio: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          cy_numerator: {
            value: subItem.cy_numerator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          cy_denominator: {
            value: subItem.cy_denominator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          cy_ratio: {
            value: subItem.cy_ratio,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_numerator: {
            value: subItem.py_numerator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_denominator: {
            value: subItem.py_denominator,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          py_ratio: {
            value: subItem.py_ratio,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
            },
          },
          variance: {
            value: subItem.variance,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          reason_for_variance: {
            value: subItem.reason_for_variance,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 10,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}

// disclosure70
export function pyTransformdis70(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure71
export function pyTransformdis71(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

// disclosure72
export function pyTransformdis72(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 6, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 6,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 6,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels);
  });
  return output.flat();
}

//disclosure73
export function pyTransformdis73(input, projectDetails = null) {
  const output = [];
  input.disclosure_data.forEach((item) => {
    const labels = [];
    if (item.isMainHeading) {
      labels.push({
        particular: {
          value: item.mainHeading,
          style: { merge_cells: 4, size: 10, fill_color: "f2e2b5", bold: true },
        },
      });
    }
    if (item.isHeaderName) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.headerName),
          style: {
            merge_cells: 4,
            height: item.headerName.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item.isHeading) {
      labels.push({
        particular: {
          value: item.heading,
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "f2e2b5",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    if (item?.sub_disclosure && item.sub_disclosure.length > 0) {
      labels.push({
        sl_no: {
          value: "Sr. No",
          style: {
            size: 10,
            bold: true,
            width: 10,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        particular: {
          value: "Particulars",
          style: {
            size: 10,
            bold: true,
            width: 30,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
          },
        },
        cy: {
          value: dateTimeMapping(projectDetails, 'cy'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
        py: {
          value: dateTimeMapping(projectDetails, 'py'),
          style: {
            size: 10,
            bold: true,
            width: 20,
            fill_color: "808080",
            horizontal_align: "left",
            vertical_align: "center",
            wrap_text: true,
          },
        },
      });
      const data = item.sub_disclosure.map((subItem) => {
        return {
          sl_no: {
            value: subItem.sr_no,
            style: {
              size: 10,
              width: 10,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
            },
          },
          particular: {
            value: subItem.header,
            style: {
              size: 10,
              width: 30,
              fill_color: "FFFFFF",
              horizontal_align: "left",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          cy: {
            value: subItem.cy,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
          py: {
            value: subItem.py,
            style: {
              size: 10,
              width: 20,
              fill_color: "FFFFFF",
              horizontal_align: "right",
              vertical_align: "center",
              wrap_text: true,
            },
          },
        };
      });
      labels.push(data);
    }
    if (item.isFooter) {
      labels.push({
        particular: {
          value: removeHTMLTags(item.footer),
          style: {
            merge_cells: 4,
            height: item.heading.length > 200 ? 200 : 80,
            size: 10,
            color: "03565A",
            fill_color: "FFFFFF",
            bold: true,
            wrap_text: true,
            horizontal_align: "left",
            vertical_align: "top",
          },
        },
      });
    }
    output.push(labels.flat());
  });
  return output;
}
