import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import CommonTextEditor from './CommonTextEditor';
import { postAPI, putAPI } from '../../services/api/CommonAPI';
import DisclosureCreateModal from '../Common/Moadel/DisclosureCreateModal';
import { socket } from '../../services/socket/socket';
import DeletedTabModal from './DeletedTabModal';
import { getDisclosureData } from '../../services/api/apiHandler';
import NBFCDisclosureNotes from './NBFC/NBFCDisclosureNotes';
import Loading from '../Common/Loader/Loading';
import Ratios from './Ratios/Ratios';
import FinancialInstrument from './FinancialInstrument/FinancialInstrument';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '50vh',
  },
  tabs: {
    maxHeight: '100vh',
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: '3rem',
    paddingBottom: '3rem',
    background: 'linear-gradient(45deg, transparent, #E5EEEE)',
    color: 'black',
    fontSize: '1rem',
    width: '15%',
  },
  tab: {
    // fontSize:'1px',
    padding: '12px 12px',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '5px',
    // maxWidth: '300px',
    // width: '220px',
    boxSizing: 'border-box',
    minHeight: '55px',
    textAlign: 'center',
    flexShrink: 0,
    fontWeight: "500",
    // lineHeight: '1.75',
    letterSpacing: '0.02857em',
    textTransform: 'none',
  },
  TabPanel: {
    width: '85%'
  },
}));

const heading = {
  width: "100%",
  height: '40px',
  backgroundColor: '#E5EEEE',
  position: 'sticky',
  top: '80px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}
const input = {
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
  height: "2.2rem", padding: "0.3rem", fontWeight: "500", margin: "0"
}

const Disclosures = ({ scrollToTop, setKey, setNoteName }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const project_id = localStorage.getItem('project_id');
  const tb_id = localStorage.getItem("tb_id");

  const [value, setValue] = useState(0);
  const [disclosuresData, setDisclosuresData] = useState([]);
  const [open, setOpen] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [companyType, setCompanyType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
    // if(reduxDisclosureData && reduxDisclosureData.length > 0){
      // setLoading(false);
      // setDeletedItems(reduxDeletedItems);
      // setDisclosuresData(reduxDisclosureData);
      // setCompanyType(reduxCompanyType);
    // }else{
      getAllDisclosureData();
    // }
  }, [])
  

  const getAllDisclosureData = () => {
    setLoading(true);
    getDisclosureData(project_id,tb_id).then(response => {
      setLoading(false);
      dispatch({ type: "DELETED_ITEMS", payload: response?.data?.deleted});
      dispatch({ type: "DISCLOSURE_DATA", payload: response?.data?.data});
      dispatch({ type: "COMPANY_TYPE", payload: response?.data?.company_type});
      dispatch({ type: "PROJECT_DATA", payload: response?.data?.project});
      dispatch({ type: "FILTER_DISCLOSURE_DATA", payload: response?.data?.data[0]});

      setDeletedItems(response?.data?.deleted);
      setDisclosuresData(response?.data?.data);
      setCompanyType(response?.data?.company_type);
    }).catch(err => {
      setLoading(false);
      swal("Error", err.response?.data?.error || "Disclosure data fetched failed !", "error");
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(companyType === "NBFC"){
      const filterData = disclosuresData.find((ele,ix) => ix === newValue);
      dispatch({ type: "FILTER_DISCLOSURE_DATA", payload: filterData});
    }
  };

  const HandleSubmit = (value) => {
    setCreateLoader(true);
    postAPI(`api/v1/disclosure/${project_id}`, { name: value }).then(res => {
      toast.success("New Disclosure Note Created Successfully.")
      if(companyType === "NBFC"){
        const lastLen = res.data.data.length - 1;
        setValue(lastLen);
        dispatch({ type: "FILTER_DISCLOSURE_DATA", payload: res.data.data[lastLen]});
      }
      setDisclosuresData(res.data?.data);
      setCreateLoader(false);
      setOpen(false);
    }).catch(err => {
      setCreateLoader(false);
      setOpen(false);
      let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time of create, please try after some time.";
      swal("Error", errorData, "error");
    })
  }

  const HandleEditChange = (index, data) => {
    let copy = [...disclosuresData]
    copy.forEach((ele, i) => {
      if (index === i) {
        ele.message = data
      }
    });

    setDisclosuresData(copy);

    socket.emit("auto-save-disclosure", {
      project_id: `${project_id}`,
      contents: copy,
    });

  }

  const subOnChange = (data, subIx, tabIx) => {
    
    let copy = [...disclosuresData]
    copy.forEach((ele, i) => {
      if (tabIx === i) {
        if(ele?.subData){
          ele?.subData.forEach((sub, Ix) => {
            if(subIx === Ix) {
              sub.message = data
              sub.index = tabIx
            }
          })
        }
      }
    });

    setDisclosuresData(copy);

    socket.emit("auto-save-disclosure", {
      project_id: `${project_id}`,
      contents: copy,
    });
  }

  const ComponentManagement = (tabIx, eachElement) => {
    if(companyType === "Manufacturing"){
      switch (eachElement?.label) {
        case "Financial Instruments - Fair value hierarchy":
          return <FinancialInstrument
            scrollToTop={scrollToTop}
            setKey={setKey}
            setNoteName={setNoteName}
          />
        case "Ratios":
          return <Ratios />
        default:
          return <CommonTextEditor
            data={eachElement?.subData ? eachElement?.subData : []}
            value={eachElement?.message}
            onChange={(data) => {
              HandleEditChange(tabIx, data);
            }}
            subOnChange={subOnChange}
            tabIx={tabIx}
            label={eachElement?.label}
          />
      }
    }else if(companyType === "NBFC"){
      if (tabIx >= 0) {
        return <NBFCDisclosureNotes tabIx={tabIx} />
      } else {
        return null;
      }
    }
  }

  const HandleRestore = (index, element) => {
    swal({
      title: "Are you sure?",
      text: "It will restore the data.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let data = { index: index, element:element }
          putAPI(`api/v1/disclosure/restore/${project_id}`, data).then(res => {
            setDisclosuresData(res.data?.data);
            setDeletedItems(res.data?.deletedItem);
            swal(res.data?.message ? res.data?.message : "Restored successfully", {
              icon: "success",
            }).then(() => {
              setIsDeleted(false);
            })
          }).catch(err => {
            let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time restore, please try after some time.";
            swal("Error", errorData, "error");
          })
        }
      });
  }

  const DeleteTab = (Ix, deletedEle) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let data = { index: Ix, element: deletedEle }
          putAPI(`api/v1/disclosure/${project_id}`, data).then(res => {
            setDisclosuresData(res.data?.data);
            setDeletedItems(res.data?.deletedItem)
            setValue(res.data?.data.length - 1)
            swal(res.data?.message ? res.data?.message : "Deleted successfully", {
              icon: "success",
            });
          }).catch(err => {
            console.log("response---", err)
            let errorData = err.response?.data?.message ? err.response?.data?.message : "Error happing at the time delete, please try after some time.";
            swal("Error", errorData, "error");
          })
        }
      });
  }

  return (
    <>
      <div style={{ backgroundColor: "#e5eeee" }} className='d-flex justify-content-end'>
        <div className='rounded deleteBtn mr-2 my-1' style={{ backgroundColor: "white", width: "fit-content", cursor: "pointer" }}
          onClick={() => { setIsDeleted(true); }}
        >
          <div className='px-4 py-2 font-sans' style={{ color: "#03565a", fontWeight: "600" }}>Deleted Tabs</div>
        </div>
      </div>
      {loading ? <Loading />
      :
        <div className={classes.root} >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            TabIndicatorProps={{ style: { backgroundColor: '#03565A',width:"3px" } }}

          >
            {
              disclosuresData && disclosuresData.length > 0 && disclosuresData.map((ele, ix) => (
                <Tab className={`${classes.tab} font-sans`} label={ele?.Id} {...a11yProps(ix)} />
              ))
            }
            <div>
              <button
                onClick={() => { setOpen(true) }}
                className='w-100 outline mb-1 py-1 border-0 font-sans'
                style={{ backgroundColor: "inherit", color: "#03565A", fontWeight: "700" }}>
                + New Note
              </button>
            </div>
          </Tabs>

          {
            disclosuresData && disclosuresData.length > 0 && disclosuresData.map((ele, ix) => (
              <TabPanel className={classes.TabPanel} value={value} index={ix}>
                <div style={{...heading, zIndex:1}}>
                 {/* <p style={{ color: "#03565a", fontWeight: "600" }} className='ml-3' >{ele?.label}</p>*/}
                  <div className='rounded deleteBtn mr-2 my-1 font-sans' style={{ backgroundColor: "white", width: "fit-content", cursor: "pointer" }}
                    onClick={() => { DeleteTab(ix, ele) }}
                  >
                    <div className='px-4 py-1 font-sans' style={{ color: "#03565a", fontWeight: "600" }}>Delete Tab</div>
                  </div>
                </div>
                {
                  ![0, 1, 2].includes(ix) &&
                  <div className='d-flex justify-content-between my-2 mx-2' style={{ gap: "1rem" }}>
                    {ele?.text1 && <p style={input} className='w-50' >{ele?.text1}</p>}
                  </div>
                }
                {ComponentManagement(ix, ele)}
              </TabPanel>
            ))
          }
        </div>
      }
      {
        open && <DisclosureCreateModal open={open} setOpen={setOpen}
          title={"Create New Item"}
          inpName={"Name"}
          btnName={"Create"}
          HandleSubmit={HandleSubmit}
          loading={createLoader}
        />
      }
      {
        isDeleted && <DeletedTabModal isDeleted={isDeleted} setIsDeleted={setIsDeleted} data={deletedItems} HandleRestore={HandleRestore} />
      }
    </>
  )
}

export default Disclosures