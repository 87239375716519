import { getNewColumns } from "../../../services/api/apiHandler";


export const fetchColumns = (project_id) => {
    return dispatch => {
        dispatch({ type: "NCT_ACTION_START" })
        getNewColumns(project_id).then((result) => {
            dispatch({
                type: "NCT_FETCH_DATA",
                payload: { data: result.data }
            });
        }).catch((err) => {
            dispatch({
                type: "NCT_FETCH_DATA_FAILED",
                payload: err.response.data.error
            })
        })
    }
}
