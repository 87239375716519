import React, { useState, useMemo } from "react";
import "./adjustments.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchSAAdjustment, fetchSALedger } from "../../redux/actions/actionStandaloneAdj";
import AdjustmentFilter from "./components/AdjustmentFilter";
import AdjustmentHeader from "./components/AdjustmentHeader";
import AdjustmentsTable from "./components/AdjustmentsTable";
import swal from "sweetalert";
import { applyAdjFilter } from "./adjustmentHelperFunction";

const Adjustments = () => {
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");

    const filterData = useSelector((initialState) => initialState.reducer.filter);
    const {
        loading,
        isSuccess,
        adjustmentData,
        copyAdjData,
        projectData,
        ledgerIsSuccess,
        ledgerData
    } = useSelector((initialState) => initialState.ADJStandaloneReducer);

    const [filterShow, setFilterShow] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [editOn, setEditOn] = useState(false);
    const [state, setstate] = useState([]);

    useMemo(() => {
        setstate([]);
        // if (!isSuccess) {
            dispatch(fetchSAAdjustment(project_id, tb_id));
        // }
        if (!ledgerIsSuccess) {
            dispatch(fetchSALedger(project_id, tb_id));
        }
    }, [ledgerIsSuccess]);

    const handleOptions = () => {
        if (editOn) {
            swal(
                "Warning",
                "Please Save the adjustment to create new adjustments",
                "warning"
            );
            return;
        }
        setShowCreate(true);
    }

    const handleApplyFilter = () => {
        dispatch(applyAdjFilter(copyAdjData, filterData, "SA_ADJ_FILTER"));
    }

    return (
        <div>
            <AdjustmentHeader
                adjustmentData= {adjustmentData}
                setFilterShow={setFilterShow}
                filterShow={filterShow}
                handleOptions={handleOptions}
            />

            <AdjustmentFilter
                actionFrom={"adjustment"}
                show={filterShow}
                handleApplyFilter={handleApplyFilter}
                setFilterShow={setFilterShow}
                setObject={() => { }}
                state={state}
                setstate={setstate}
            />

            <AdjustmentsTable
                loading={loading}
                adjustmentData={adjustmentData}
                ledgerData={ledgerData}
                projectData={projectData}
                showCreate={showCreate}
                setShowCreate={setShowCreate}
                setEditOn={setEditOn}
                editOn={editOn}
            />

            <div className="records">
                <div className="custom-font-600 text-secondary" >
                    {adjustmentData?.length} Records
                </div>
                <div className="ml-auto custom-font-600 text-secondary" >
                    (+) stands for Debit, (-) stands for Credit
                </div>
            </div>
        </div>
    );
};

export default Adjustments;
