import axios from "axios"

const auth = localStorage.getItem("auth_token");
const headers = {
    "x-auth-token": auth,
};

export const getCashflow = async (project_id, tb_id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/v1/cashflow/${project_id}/${tb_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const addAdjustmentItem = async (project_id, tb_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`api/v1/cashflow/add-adjustment/${project_id}/${tb_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const getAdjustmentOptions = async (project_id, tb_id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/v1/cashflow/adjustment-header/${project_id}/${tb_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const addAdjustmentHeader = async (project_id, tb_id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/v1/cashflow/add-adjustment-header/${project_id}/${tb_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const removeAdjustmentHeader = async (project_id, tb_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`api/v1/cashflow/add-adjustment-header/${project_id}/${tb_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const addNewOption = async (project_id, tb_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`api/v1/cashflow/adjustment-add-option/${project_id}/${tb_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const getAdjLog = async (project_id, tb_id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`api/v1/cashflow/adjustment-log/${project_id}/${tb_id}`, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const editAdjustment = async (project_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`api/v1/cashflow/edit-adjustment/${project_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const cashflowReferenceUpdate = async (project_id, tb_id, data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`api/v1/cashflow/reference-mapping/${project_id}/${tb_id}`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const addNewWorkingOption = async (data) => {
    return new Promise((resolve, reject) => {
        axios
            .put(`api/v1/cashflow/add-new-option`, data, { headers })
            .then((res) => {
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};