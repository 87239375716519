import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../Navbar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router';
import { editAdjustment, getAdjLog } from '../../../../services/api/StandAlone/cashFlow';
import swal from 'sweetalert';
import "../../cashFlow.css"
import AdjustmentLogsEditModal from './AdjustmentLogsEditModal';
import Loading from '../../../Common/Loader/Loading';
import moment from 'moment';

const AdjustmentLogPage = () => {
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');
    const history = useHistory();
    const [logsData, setLogsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [element, setElement] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getAdjLog(project_id, tb_id).then(res => {
            setLogsData(res.data.logsData)
        }).catch(err => swal("Error", err, "error")).finally(() => setLoading(false));
    }, [])

    const EditSubmit = (value) => {
        editAdjustment(project_id, { value }).then(res => {
            setLogsData(res.data.adjustment);
            setOpen(false)
        }).catch(err => swal("Error", err, "error"))
    }

    return (
        <>
            <Navbar text="Financial Year: 2021 - 2022" />

            <div>
                <button className='ml-2 mt-3 rounded back-btn' style={{ border: "none", backgroundColor: "inherit" }}
                    onClick={() => { history.push(`/financialstatement/cashflow/working`) }}
                >
                    <div className='d-flex px-3' style={{ color: "#03565a", fontWeight: "600" }}><ArrowBackIcon /> Back</div>
                </button>
            </div>

            <div className='mx-3'>
                {
                    loading ? <Loading /> :
                        <table>
                            <thead>
                                <th>User Email</th>
                                <th style={{ width: "7rem" }}>Date</th>
                                <th>Description</th>
                                <th>1st Item</th>
                                <th>2nd Item</th>
                                <th>1st Value</th>
                                <th>2nd Value</th>
                                <th>Message</th>
                            </thead>
                            <tbody>
                                {
                                    logsData.length > 0 ? logsData.map((ele, ix) => (
                                        <tr key={ix} >
                                            <td className='text-left pl-2'>
                                                {ele?.user_id?.email}
                                            </td>
                                            <td className='text-left pl-2' style={{ width: "7rem" }}>
                                                {ele?.created_at ? moment(ele?.created_at).format("DD-MM-YYYY") : ""}
                                            </td>
                                            <td className='text-left pl-2'>
                                                {ele?.element?.description}
                                            </td >
                                            <td className='text-left pl-2'>
                                                {ele?.element?.subHeader_val}
                                            </td>
                                            <td className='text-left pl-2'>
                                                {ele?.element?.subHeader1_val}
                                            </td>
                                            <td className='text-right pr-2'>
                                                {ele?.element?.amount}
                                            </td>
                                            <td className='text-right pr-2'>
                                                {ele?.element?.amount1}
                                            </td>
                                            <td className='text-left pl-2'>
                                                {ele?.message}
                                            </td>
                                        </tr>
                                    )) : <tr><td colSpan="8">No Data Found</td></tr>
                                }
                            </tbody>
                        </table>
                }
            </div>

            {
                open && <AdjustmentLogsEditModal open={open} setOpen={setOpen} element={element} HandleSubmit={(value) => EditSubmit(value)} />
            }
        </>
    )
}

export default AdjustmentLogPage