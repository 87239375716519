
import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './notes.css'
import {useDispatch,useSelector} from 'react-redux'
import NotesData from './NotesData'
import { fetchTTBSData } from '../../redux/actions/TemplateTabs/actionBS';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '100vh',

    
  },
  indicator: {
    backgroundColor: '#03565A',
    width: "3px"
  },
  tabs: {
    maxHeight:'100vh',
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop:'3rem',
    paddingBottom:'3rem',
    background:'linear-gradient(45deg, transparent, #E5EEEE)',    
    color:'Black',
    fontSize:'1rem',
    width: '260px',
  },
  tab: {
      padding: '12px 12px',
      overflow: 'hidden',
      position: 'relative',
      fontSize: '5px',
      boxSizing: 'border-box',
      minHeight: '55px',
      textAlign: 'center',
      flexShrink: 0,
      fontWeight: "500",
      letterSpacing: '0.02857em',
      textTransform: 'none',
  },
  TabPanel: {
    width:'100%',
    minWidth:"80%"
  },
}));

const heading = {
  height:'50px',
  backgroundColor:'#E5EEEE',
  position:'sticky',
  display:'flex',
  alignItems:'center',
}

const headingText = {
  fontSize:'1.2 rem',
  fontWeight:'700',


}


export default function VerticalTabs(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const presetNoteBsNo = useSelector((initialState) => initialState.HandlePopulateFsNotesReducer.noteBsNo);
  const [value, setValue] = useState(0);
  const [noteData, setNoteData] = useState([]);
  const handleChange = (event, newValue) => {
    const noteNo = noteData.find((note, index) => index === newValue)?.notes_index || 0;
    dispatch({ type: "NOTE_BS_NUMBER", payload: noteNo });
    setValue(newValue);
  };
  const tb_id = localStorage.getItem("tb_id");
  const project_id = localStorage.getItem("project_id");

  const { isSuccess, response, set_payload } = useSelector((state) => state.TempTabBSReducer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows = response?.balance_sheet ?? [];
 
  const fetchBalanceSheetData = async () => {
    if(!isSuccess || set_payload !== JSON.stringify({project_id, tb_id})){
      dispatch(fetchTTBSData(project_id, tb_id))
    }
  }
  
  useEffect(() => {
    if(noteData.length > 0){
      const noteInd = noteData.findIndex(note => note.notes_index === presetNoteBsNo);
      setValue(noteInd >= 0 ? noteInd : 0)
    }
  }, [presetNoteBsNo, noteData])

  useEffect(() => {
    (async () => {
      await fetchBalanceSheetData();
    })()
  }, [])


  const sortingNotes = (notesData) => {
    const output = notesData.reduce((result, currentItem) => {
        const existingItem = result.find(item => item.notes_grp === currentItem.notes_grp && item.notes_no === currentItem.notes_no);
      
        if (existingItem) {
          if (!Array.isArray(existingItem.fs_grp)) {
            existingItem.fs_grp = [existingItem.fs_grp];
          }
      
          if (!existingItem.fs_grp.includes(currentItem.fs_grp)) {
            existingItem.fs_grp.push(currentItem.fs_grp);
          }
        } else {
          result.push({ ...currentItem });
        }
      
        return result;
      }, []);
    return output;
  }

  useEffect(()=> {
    const notes_ = Object.keys(rows).map((key, index)=>rows[key]
    .map((notes)=>{ return {
      fs_grp: key,
      notes_grp: notes.notes_grp,
      notes_no: notes.notes_no,
      notes_index: notes.notes_index
    }
    })).flat()
    const resultNotes = sortingNotes(notes_);
    setNoteData(resultNotes );
  },[rows])



  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={`${classes.tabs} bsnotetab`}
        // Add the indicator color style here
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {noteData.map((note, key) => (
          <Tab className={`${classes.tab} font-sans`} label={'Note - ' + note?.notes_grp} key={key} {...a11yProps(0)} />
        ))}
      </Tabs>
      {noteData.map((note, key) => (
        <TabPanel className={classes.TabPanel} value={value} index={key} key={key}>
          <div style={heading}>
            <p style={headingText} className='ml-3 font-sans'>
              {'Note ' + note?.notes_index + ' - ' + note?.notes_grp}
            </p>
          </div>
          <NotesData
            setShowCreateInput={props.setShowCreateInput}
            note='3'
            FinalStr='SET_NOTE3'
            NoteStr='note3'
            setSid={props.setSid}
            setNid={props.setNid}
            setNoteNum={props.setNoteNum}
            notes_no={note.notes_no}
            fs_grp={note.fs_grp}
            render={props.render}
          />
        </TabPanel>
      ))}
    </div>
  );
}