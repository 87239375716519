import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addAdjustmentHeader, addAdjustmentItem, addNewOption, getAdjustmentOptions, removeAdjustmentHeader } from '../../../services/api/StandAlone/cashFlow';
import swal from 'sweetalert';
import AddNewAdjustmentModal from './AddNewAdjustmentModal';
import Loading from '../../Common/Loader/Loading';

const AdjustmentCashflowWorking = () => {
    const history = useHistory();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id');
    const [adjData, setAdjData] = useState([]);
    const [open, setOpen] = useState(false);
    const [subGroupElement, setSubGroupElement] = useState({
        id: "",
        description: "",
        indexId: "",
        element: {},
        isCheck: ""
    });
    const [workId, setWorkId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getAdjustmentOptions(project_id, tb_id).then(res => {
            setWorkId(res.data?.workingId)
            setAdjData(res.data.data);
        }).catch(err => {
            swal("Error", err, "error");
        }).finally(() => setLoading(false));
    }, []);

    const AddNew = () => {
        setLoading(true);
        addAdjustmentHeader(project_id, tb_id).then(response => {
            setAdjData(response.data.data);
        }).catch(err => {
            swal("Error", err, "error");
        }).finally(() => setLoading(false));
    }

    const RemoveItem = (element, i) => {
        const data = {
            indexId: i,
            element
        }
        setLoading(true);
        removeAdjustmentHeader(project_id, tb_id, data).then(response => {
            setAdjData(response.data.data);
        }).catch(err => {
            swal("Error", err, "error");
        }).finally(() => setLoading(false));
    }

    const HandleChange = (event, index, key) => {
        if (event.target.value === "add_option" || event.target.value === "add_option1") {
            const optData = adjData.find((ele, ix) => ix === index);
            // setSubGroupElementData({ ...subGroupElementData, indexId: index, isCheck: event.target.value })
            setSubGroupElement({
                ...subGroupElement,
                id: event.target.value === "add_option" ? optData?.header_val : optData?.header1_val,
                description: optData?.description,
                indexId: index,
                element: optData,
                isCheck: event.target.value
            });
            setOpen(true)
        }

        const copy = [...adjData];
        copy[index][key] = (key === "amount" || key === "amount1") ? Number(event.target.value) : event.target.value;

        if (key === "header_val" || key === "header1_val") {
            const headerName = key === "subHeader_val" ? "header" : "header1";
            const filterOption = adjData[index][headerName].find(ele => ele?.faceValueId === Number(event.target.value));

            const keyName = key === "header_val" ? "subHeader" : "subHeader1";
            const valueName = key === "header_val" ? "subHeader_val" : "subHeader1_val";
            copy[index][keyName] = filterOption?.subData
            copy[index][valueName] = filterOption?.subData[0]?.particular

            const keyName2 = key === "header_val" ? "fromWorking" : "fromWorking1";
            const valueName2 = key === "header_val" ? "workingId" : "workingId1";
            copy[index][keyName2] = filterOption?.subData[0]?.fromWorking
            copy[index][valueName2] = filterOption?.subData[0]?.fromWorking[0]?.workingId || ""
        }
        if ((event.target.value !== "add_option" && event.target.value !== "add_option1") && (key === "subHeader_val" || key === "subHeader1_val")) {
            console.log("within block")
            const headerName = key === "subHeader_val" ? "subHeader" : "subHeader1";

            const filterOption = adjData[index][headerName].find(ele => ele?.particular === event.target.value) || [];
            const keyName = key === "subHeader_val" ? "fromWorking" : "fromWorking1";
            const valueName = key === "subHeader_val" ? "workingId" : "workingId1";

            copy[index][keyName] = filterOption?.fromWorking
            copy[index][valueName] = (filterOption?.fromWorking[0]?.workingId) || ""
        }

        setAdjData(copy);
    }

    const HandleSave = (element, indexId) => {
        if (element.amount1 + element.amount !== 0) {
            swal("Warning", "The difference between both amounts is always zero.", "warning");
            return;
        }

        const data = {
            indexId,
            element
        };

        addAdjustmentItem(project_id, tb_id, data).then((res) => {
            setAdjData(res.data.data);
            swal("Success", `${res.data.message} go to adjustment log.`, "success");
        }).catch(err => {
            swal("Error", err, "error");
        })

    }

    const AddSubGroup = (value) => {
        const data = {
            description: subGroupElement.description,
            name: value,
            id: Number(subGroupElement.id),
            workingId: workId + 1,
            adjIndexId: subGroupElement.indexId,
            adjElement: subGroupElement.element,
            isCheck: subGroupElement.isCheck
        }
        addNewOption(project_id, tb_id, data).then(res => {
            setWorkId(res.data?.workingId);
            setAdjData(res.data.data);

            setOpen(false);
        }).catch(err => {
            setOpen(false);
            swal("Error", err, "error");
        })
    }

    return (
        <>
            <div className='mx-4'>
                <p className='text-center border py-1 mt-2' style={{ backgroundColor: "#e1f0fa", fontWeight: "600" }}>Add adjustment </p>

                <div className='d-flex justify-content-between align-items-center w-100'>
                    <button className='rounded back-btn' style={{ border: "none", backgroundColor: "inherit" }}
                        onClick={() => { history.push(`/financialstatement/cashflow/working`) }}
                    >
                        <div className='d-flex custom-common-theme-text-color custom-font-600' ><ArrowBackIcon /> Back</div>
                    </button>

                    <button className='rounded deleteBtn' style={{ border: "none", backgroundColor: "white" }}
                        onClick={() => { AddNew() }}
                    >
                        <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Add New Item +</div>
                    </button>
                </div>

                <div>
                    {
                        loading ? <Loading /> :
                        adjData?.length > 0 && adjData.map((ele, ix) => (
                            <div className='adjustment-shadow mt-2 p-2'>
                                <div className='d-flex'>
                                    <p className='mt-0 mb-0' style={{ width: "16.8%" }}>Adjustment description</p>
                                    <textarea placeholder='Interest' style={{ width: "62%", height: "3rem" }} className='p-2 adjustment-border rounded' value={ele?.description} onChange={(e) => { HandleChange(e, ix, "description") }} />
                                </div>
                                <div className='d-flex' key={ix}>
                                    <div>
                                        <div className='d-flex mt-2' style={{ gap: "1rem" }}>
                                            <p className='mt-0 mb-0' style={{ width: "20%" }}>Adjustment to be shown under</p>
                                            <select style={{ width: "25%" }} value={ele?.header_val} onChange={(e) => { HandleChange(e, ix, "header_val") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.header.length > 0 && ele?.header.map((ele, i) => (
                                                        <option className='adj-select' key={i} value={ele?.faceValueId} >{ele?.heading}</option>
                                                    ))
                                                }
                                            </select>
                                            <select style={{ width: "25%" }} value={ele?.subHeader_val} onChange={(e) => { HandleChange(e, ix, "subHeader_val") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.subHeader && ele?.subHeader.length > 0 && ele?.subHeader.map((ele, i) => (
                                                        <option key={i} value={ele?.particular} >{ele?.particular}</option>
                                                    ))
                                                }
                                                <option value={"add_option"}>{"+ Add New"}</option>
                                            </select>
                                            <select style={{ width: "25%" }} value={ele?.workingId} onChange={(e) => { HandleChange(e, ix, "workingId") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.fromWorking.length > 0 ? ele?.fromWorking.map((ele, i) => (
                                                        <option className='adj-select' key={i} value={ele?.workingId} >{ele?.name}</option>
                                                    )) : <option className='adj-select' value={""} >{"not available"}</option>
                                                }
                                            </select>
                                            <input style={{ width: "10%" }} placeholder='Amount' type='number' className='p-2 adjustment-border rounded' value={ele?.amount} onChange={(e) => { HandleChange(e, ix, "amount") }} />
                                        </div>

                                        <div className='d-flex mt-2' style={{ gap: "1rem" }}>
                                            <p className='mt-0 mb-0' style={{ width: "20%" }}>Adjustment to be shown under</p>
                                            <select style={{ width: "25%" }} value={ele?.header1_val} onChange={(e) => { HandleChange(e, ix, "header1_val") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.header1.length > 0 && ele?.header1.map((ele, i) => (
                                                        <option key={i} value={ele?.faceValueId} >{ele?.heading}</option>
                                                    ))
                                                }
                                            </select>
                                            <select style={{ width: "25%" }} value={ele?.subHeader1_val} onChange={(e) => { HandleChange(e, ix, "subHeader1_val") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.subHeader1 && ele?.subHeader1.length > 0 && ele?.subHeader1.map((ele, i) => (
                                                        <option key={i} value={ele?.particular} >{ele?.particular}</option>
                                                    ))
                                                }
                                                <option value={"add_option1"} >{"+ Add New"}</option>
                                            </select>
                                            <select style={{ width: "25%" }} value={ele?.workingId1} onChange={(e) => { HandleChange(e, ix, "workingId1") }} className='p-3 adjustment-border rounded'>
                                                {
                                                    ele?.fromWorking1.length > 0 ? ele?.fromWorking1.map((ele, i) => (
                                                        <option className='adj-select' key={i} value={ele?.workingId} >{ele?.name}</option>
                                                    )) : <option className='adj-select' value={""} >{"not available"}</option>
                                                }
                                            </select>
                                            <input style={{ width: "10%" }} placeholder='Amount' type='number' className='p-2 adjustment-border rounded' value={ele?.amount1} onChange={(e) => { HandleChange(e, ix, "amount1") }} />
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center ml-2'>
                                        <button className='my-2 rounded deleteBtn mt-2 border-0 bg-white'
                                            onClick={() => { HandleSave(ele, ix) }}
                                        >
                                            <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Save</div>
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center ml-2'>
                                        <button className='my-2 rounded deleteBtn mt-2' style={{ border: "none", backgroundColor: "white" }}
                                            onClick={() => RemoveItem(ele, ix)}
                                        >
                                            <div className='px-3 py-1' style={{ color: "#03565a", fontWeight: "600" }}>Remove</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            {
                open && <AddNewAdjustmentModal open={open} setOpen={setOpen} HandleSubmit={(value) => AddSubGroup(value)} />
            }
        </>
    )
}

export default AdjustmentCashflowWorking