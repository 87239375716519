import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "45%",
    bgcolor: 'background.paper',
    border: "1.3px",
    p: 2,
    borderRadius: "0.3rem",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
};

const DeletedSoftNotesModal = ({ setIsOpen, isOpen, data, HandleRestore }) => {
    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ ...style, width: "100%" }}>
                        <div id="parent-modal-title" className='d-flex justify-content-between'>
                            <h2 className='financial-restore-modal' >Restore General Notes Tabs</h2>
                            <div onClick={() => setIsOpen(false)} className='d-flex justify-content-center align-items-center' style={{ width: "3rem", cursor: "pointer" }}><CloseIcon className='financialInstrument-deleteIconHover' /></div>
                        </div>
                        <div id="parent-modal-description" style={{ height: "33rem", overflowY: "scroll" }} >
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: "70%" }}>Tab Name</th>
                                        <th style={{ width: "30%" }}>Restore</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 && data.map((name, i) => (
                                            <tr key={i}>
                                                <td style={{ width: "70%" }}>{name?.element?.name}</td>
                                                <td style={{ width: "30%", cursor: "pointer" }} onClick={() => { HandleRestore(i) }}>
                                                    <RestoreFromTrashIcon className="financialInstrument-restoreIconHover" />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default DeletedSoftNotesModal

