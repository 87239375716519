import Sidbarv2 from "../../Workspace-v2/Sidbarv2";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import "../../../../src/WorkSpace/SignUp2.css";
import { Navbar } from "../../../Components/Navbar";
import { getAPI } from "../../../services/api/StandAlone/projectAPIs";
// import PresetInfo from "../../../PrivatePages/consolidatedFinancialStatement/PresetInfo";


import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useHistory, useLocation } from "react-router";
import { GetAllProject, updateProject } from "../ApiServices/apiHandler";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../redux/actionTypes";
import swal from "sweetalert";
import "../../../../src/WorkSpace/font.css";
import { fetchWSData } from "../../../redux/actions/workspace/actionWorkspace";
import EditProject from "../Modal/editProject";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#296F73" : "#308fe8",
  },
}));

const FinancialStatement = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const tableData = useSelector((state) => state.reducer.tableData);
  const [data, setData] = useState([]);
  const [barData, setBarDAta] = useState("");
  const [projectId, setProjectId] = useState("");
  const workspace_data = useSelector((state) => state.WSReducer);
  const [allProjectData, setallProjectData] = useState("");
  const [editModal, handleEditModal] = useState(false);
  const [editProject, setEditProject] = useState({
    company: "",
    name_finance: "",
    nature_finance: "",
    gaap: "",
    account: "",
  });
  const [search, setSearch] = useState("");

  const handleClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    setProjectId(id);
    setallProjectData(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleEditModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // end popover
  const location = useLocation();
  const dispatch = useDispatch();
  let history = useHistory();
  let a = location.pathname;
  let w_id = a.split("/");
  const [allProject, setAllProject] = useState([]);
  useEffect(() => {
    if (workspace_data.isSuccess === false) {
      dispatch(fetchWSData(w_id[2]));
    }
    AllProject();
    setData(tableData);
    selectedrows();
  }, []);

  const AllProject = async () => {
    const res = await GetAllProject(w_id[2]);
    if (res.status === 200) {
      setAllProject(res.data.project);
    }
  };
  let total = 0;
  let finaldata = 0;
  const lenghtdata = data.length;
  const selectedrows = () => {
    total = total + 1;
    finaldata = (total / lenghtdata) * 100;
    setBarDAta(Math.round(finaldata));
  };
  function handleDelete(data) {
    if (user.responsibilities === "approver") {
      swal(
        "Warning",
        "You Don't have Access to Delete Financial Statement",
        "warning"
      );
    } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to view the project",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          getAPI(`api/v1/project/delete-project/${data}`)
            .then((res) => {
              swal("", "Project has been deleted!", {
                icon: "success",
              });
              AllProject();
            })
            .catch((err) => {
              console.log("error delete project ---", err.response);
              swal("", "Project not deleted!", "error");
            });
        } else {
          swal("", "Project not deleted!", "error");
        }
      });
    }
  }
  function handleEnter(e, id) {
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
    };
    axios
      .get(`api/v1/tb-onboarding/get-tb/${id}`, { headers })
      .then((response) => {
        dispatch({ type: actionTypes.SET_TB_ID, payload: response.data.tb_id });
        window.localStorage.setItem("tb_id", response.data.tb_id);
        localStorage.setItem("project_id", response.data.project_id);
        axios
          .get(`api/v1/grouping/fs/${id}`, { headers })
          .then((res) => {
            if (res.data.fs_grp === []) {
              return;
            }
            const gp_data = {};
            gp_data.grp = res.data.fs_grp;
            dispatch({
              type: actionTypes.SET_EDITSUBGROUPING,
              payload: gp_data,
            });
          })
          .catch((error) => {
            console.log(error.response, "error");
          });
        history.push(`/createProject/DataMapping/${id}`);
      })
      .catch((error) => {
        console.log(error.response, "error");
        // swal("", "Cannot found any TB inside this project", "error");
        history.push("/createProject/uploadTb");
      });
  }
  const handleData = () => {
    if (user.responsibilities === "approver") {
      swal(
        "Warning",
        "You Don't have Access to Create Financial Statement",
        "warning"
      );
    } else {
      history.push(`/createProject/QuestionnaireNew/${w_id[2]}`);
    }
  };

  const handleEdit = (data) => {
    if (user.responsibilities === "approver") {
      swal(
        "Warning",
        "You Don't have Access to Edit Financial Statement",
        "warning"
      );
    } else {
      handleEditModal(true);
    }
  };

  const goToPage = () => {
    history.push('/preset-information'); 
  };

  const handleChnageEdit = (data, field) => {
    setEditProject({ ...editProject, [field]: data });
  };

  const submitEdit = async (id) => {
    let alldata = {
      company: editProject.company,
      project_name: editProject.name_finance,
      company_type: editProject.nature_finance,
      gaap_selection: editProject.gaap,
      preset_name: editProject.account,
    };
    const res = await updateProject(id, alldata);
    if (res.status === 200) {
      handleClose();
      swal("", "Updated Successfully", "success");
      AllProject();
    }
  };

 
  //search option
  const handleChange = (event) => {
    const searchData = event.target.value;
    setSearch(searchData);
    if (searchData === "") {
      AllProject();
    } else {
      const filerData = allProject.filter((project) =>
        project?.project_name.includes(searchData)
      );
      setAllProject(filerData);
    }
  };
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2 w_id={w_id} />
          </div>
          <div class="px-4 w-100 mt-3">
            {/* <div className="d-flex justify-content-between mt-4">
              <p className="fs-head font-sans">Financial statements</p>
              {
              workspace_data.response[0]?.access_status === "view" ? (
                <Button
                  style={{
                    height: "40px",
                    width: "250px",
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontWeight: "normal",
                  }}
                  disabled
                >
                  <span style={{ marginRight: "8px" }}>
                    <AddIcon />
                  </span>
                  Create Financial Statement
                </Button>
              ) : (
                <Button
                className="font-sans"
                  style={{
                    height: "40px",
                    width: "250px",
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontWeight: "normal",
                  }}
                  onClick={handleData}
                >
                  <span style={{ marginRight: "8px" }}>
                    {" "}
                    <AddIcon />
                  </span>
                  Create Financial Statement
                </Button>
                 <Button
                 className="font-sans"
                   style={{
                     height: "40px",
                     width: "250px",
                     border: "2px solid #03565A",
                     color: "#03565A",
                     textTransform: "none",
                     borderRadius: "5px",
                     fontWeight: "normal",
                   }}
                   onClick={handleData}
                 >
                   <span style={{ marginRight: "8px" }}>
                     {" "}
                     <AddIcon />
                   </span>
                   Create Financial Statement 2
                 </Button>
              )}
            </div> */}

            <div className="d-flex justify-content-between mt-4">
              <p className="fs-head font-sans">Financial statements</p>
              {workspace_data.response[0]?.access_status === "view" ? (
                <Button
                  style={{
                    height: "40px",
                    width: "250px",
                    border: "2px solid #03565A",
                    color: "#03565A",
                    textTransform: "none",
                    borderRadius: "5px",
                    fontWeight: "normal",
                  }}
                  disabled
                >
                  <span>
                    <AddIcon />
                  </span>
                  Create Financial Statement
                </Button>
              ) : (
                <div className="d-flex" style={{ gap: "5px" }}>
                  <Button
                    className="font-sans"
                    style={{
                      height: "40px",
                      width: "150px",
                      border: "2px solid #03565A",
                      color: "#03565A",
                      textTransform: "none",
                      borderRadius: "5px",
                      fontWeight: "normal",
                    }}
                    onClick={goToPage}
                  >
                    <span style={{ marginRight: "8px" }}>
                      <AddIcon />
                    </span>
                    Translate FS
                  </Button>
                  <Button
                    className="font-sans"
                    style={{
                      height: "40px",
                      width: "250px",
                      border: "2px solid #03565A",
                      color: "#03565A",
                      textTransform: "none",
                      borderRadius: "5px",
                      fontWeight: "normal",
                    }}
                    onClick={handleData}
                  >
                    <span style={{ marginRight: "8px" }}>
                      <AddIcon />
                    </span>
                    Create Financial Statement
                  </Button>
                </div>
              )}
            </div>

            <TableContainer component={Paper} className="wpTableContainer">
              <Table aria-label="caption table">
                <TableHead>
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginLeft: "15px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        border: "1px solid gray",
                        borderRadius: "7px",
                        marginTop: "5px",
                      }}
                    >
                      <SearchIcon
                        style={{
                          paddingLeft: "5px",
                        }}
                      />
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          marginTop: "0px",
                          padding: "10px",
                          borderRadius: "7px",
                          width: "100%",
                        }}
                        type="search"
                        name="search"
                        value={search}
                        onChange={handleChange}
                        placeholder="Search for a financial statement"
                      />
                    </div>
                  </div>
                  <TableRow>
                    <TableCell
                      className="font-inter"
                      style={{ color: "#5d615f", backgroundColor: "#f5f5f5" }}
                    >
                      {" "}
                      FINANCIAL STATEMENT NAME
                    </TableCell>
                    <TableCell
                      className="font-inter"
                      align="center"
                      style={{
                        width: "240px",
                        color: "#5d615f",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      PERIOD END
                    </TableCell>
                    <TableCell
                      className="font-inter"
                      align="center"
                      style={{
                        width: "240px",
                        color: "#5d615f",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      GROUPING STATUS
                    </TableCell>
                    <TableCell
                      className="font-inter"
                      align="center"
                      style={{
                        width: "240px",
                        color: "#5d615f",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      LOGICAL ERRORS
                    </TableCell>
                    <TableCell
                      className="font-inter"
                      align="center"
                      style={{ color: "#5d615f", backgroundColor: "#f5f5f5" }}
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allProject.length > 0 &&
                    allProject.map((det) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className="font-sans"
                              style={{
                                backgroundColor: "white",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                handleEnter(e, det._id);
                              }}
                            >
                              {det.project_name}
                            </TableCell>
                            <TableCell
                              className="font-sans"
                              align="center"
                              style={{ color: "darkgrey" }}
                            >
                              {det?.current_year &&
                                moment(det?.current_year).format("DD-MM-YYYY")}
                            </TableCell>
                            <TableCell
                              align="center"
                              className=" px-5 font-sans"
                            >
                              <div
                                className={`${
                                  det.grouping_status === "In progress"
                                    ? "inprogress"
                                    : det.grouping_status === "Completed"
                                    ? "completed"
                                    : "notstarted"
                                }`}
                              >
                                {det?.grouping_status}
                              </div>
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                              <Badge className="badgePending">{""}</Badge>
                            </TableCell>
                            <TableCell align="center" className="font-sans">
                              <MoreVertIcon
                                aria-describedby={id}
                                variant="contained"
                                onClick={(e) => handleClick(e, det._id, det)}
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ width: "101px" }}
            >
              <Typography sx={{ p: 2 }}>
                <div
                  style={{ cursor: "pointer", fontSize: "12px" }}
                  onClick={() => {
                    handleEdit(allProjectData);
                  }}
                >
                  Edit
                </div>
                <div
                  onClick={() => {
                    handleDelete(projectId);
                  }}
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  Delete
                </div>
              </Typography>
            </Popover>
          </div>
        </div>
      </div>

      <EditProject
        open={editModal}
        handleClose={handleClose}
        // editData={companyData}
        handleChnageEdit={handleChnageEdit}
        submitEdit={submitEdit}
        allProjectData={allProjectData}
      />
    </>
  );
};

export default FinancialStatement;
