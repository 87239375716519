import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import {
  lineGroupStyle,
  totalStyle,
  totaledStyle,
  NoteStyle,
} from "../Styles/style";
import { CircularProgress } from "@mui/material";
import { getProjectDetailById } from "../services/apiHandler";
import {getRandomKey} from "../../../helper/calculation";
import { useDispatch, useSelector } from "react-redux";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";
import { fetchIR } from "../../../redux/actions/actionIr";

export const NewConsoleCashflow = (props) => {
  const {
    pnlStatementData,
    subsiaryData,
    currentCompany,
    currentCompProjectId,
    CompanyName,
  } = props;

  const { loading, isSuccess, response, error_message } = pnlStatementData;

  const finalSubsidiaryData =
    response?.data?.project_ids_pnl?.map((data) => data.pnl_statement) ?? [];
  const currentProjectPnl = response.data?.pnl_statement?.pnl_statement ?? [];
  const [no, setNo] = useState([]);
  const fsRows = Object.keys(currentProjectPnl);
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [allIrList, setAllIr] = useState([]);
  const fetchIr = useSelector((state) => state.IRReducer);
  const subsidiaryData = useSelector((state) => state.SubsidiaryConsoleReducer);
  const [irOtherDetails, setIrOtherDetails] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getCurrentDataName(currentCompProjectId);
    AllIrData();
    let noArr = [];
    fsRows.map((row, i) => {
      currentProjectPnl[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
    dispatch(fetchIR());
  }, [currentProjectPnl]);

  const getCurrentDataName = async (currentCompProjectId) => {
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res?.data?.project?.project_name);
  };

  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
      setIrOtherDetails(res.data?.IrOtherDetails);
    }
  };
  
 
  return (
    <>
      {loading ? (
        <CircularProgress disableShrink />
      ) : isSuccess ? (
        <>
          <div>
            <table style={{ width: "100%" }}>
              <TableHeader
                currentCompany={currentCompany}
                subsiaryData={subsiaryData}
                currentProjectName={currentProjectName}
                browse_fs_ids={subsidiaryData?.response?.otherData
                  ?.map((data) =>
                    subsiaryData.some((item) => item.Ir_id === data._id)
                      ? data.investment_company_id
                      : null
                  )
                  .filter(Boolean)}
                CompanyName={CompanyName}
              />
              <tbody>
                {fsRows.map((group, groupIndex) => (
                  <React.Fragment key={getRandomKey()}>
                    <tr style={totaledStyle} key={getRandomKey()}>
                      <td style={{ minWidth: "300px" }}>{group}</td>
                      <td>{}</td>
                      <td>554232</td>
                      <td>675432</td>
                      {finalSubsidiaryData.length > 0 &&
                        finalSubsidiaryData.map((project_pnl, sub_index) => (
                          <React.Fragment>
                            <td>7654</td>
                            <td>654</td>
                          </React.Fragment>
                        ))}
                      <td>5645</td>
                      <td>545</td>
                      <td>5646</td>
                      <td>45678</td>
                      <td>654534</td>
                      <td>45464</td>
                    </tr> 
                    {currentProjectPnl[group].map(
                      (noteGroup, noteGroupIndex) => (
                        <React.Fragment key={getRandomKey()}>
                          <tr style={lineGroupStyle} key={getRandomKey()}>
                            <td>{noteGroup.notes_grp}</td>
                            <td style={NoteStyle}>--</td>
                            <td>1435466</td>
                            <td>5664</td>
                            {finalSubsidiaryData.length > 0 &&
                              finalSubsidiaryData.map(
                                (project_pnl, sub_index) => (
                                  <React.Fragment>
                                    <td>5664</td>
                                    <td>546464</td>
                                  </React.Fragment>
                                )
                              )}
                            <td>5455</td>
                            <td>45364</td>
                            <td>56453</td>
                            <td>65655</td>
                            <td>43353</td>
                            <td>34343</td>
                          </tr>
                          {groupIndex === 1 && noteGroupIndex === 9 ? (
                            <>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {
                                    "Profit before exceptional items, share of net profits of investments accounted for using equity method and tax"
                                  }
                                </td>
                                <td>{}</td>
                                <td>33434</td>
                                <td>45454</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td></td>
                                        <td></td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>675656</td>
                                <td>65454</td>
                                <td>56564</td>
                                <td>955694</td>
                                <td>989454</td>
                                <td>{"-"}</td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {
                                    "Share of net profit of associates and joint ventures accounted using equity method"
                                  }
                                </td>
                                <td>{}</td>
                                <td>-</td>
                                <td>-</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>-</td>
                                        <td>-</td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>598569</td>
                                <td>69509</td>
                                <td>59645405</td>
                                <td>6956459</td>
                                <td>984945</td>
                                <td>{"-"}</td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>
                                  {"Profit before exceptional items and tax"}
                                </td>
                                <td>{}</td>
                                <td>5656454</td>
                                <td>56565</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>54545</td>
                                        <td>56454</td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>5644</td>
                                <td>4546</td>
                                <td>65656</td>
                                <td>656454</td>
                                <td>56645</td>
                                <td>5652</td>
                              </tr>
                            </>
                          ) : groupIndex === 1 && noteGroupIndex === 11 ? (
                            <React.Fragment key={getRandomKey()}>
                              <tr style={totaledStyle} key={getRandomKey()}>
                                <td>{"Total Tax"}</td>
                                <td>{}</td>
                                <td>2322</td>
                                <td>54545</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>545454</td>
                                        <td>5645</td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>3948349</td>
                                <td>45485</td>
                                <td>34344</td>
                                <td>32323</td>
                                <td>454</td>
                                <td>454534</td>
                              </tr>
                              <tr style={totalStyle} key={getRandomKey()}>
                                <td>{"Profit for the Year"}</td>
                                <td>{}</td>
                                <td>645343</td>
                                <td>553442</td>
                                {finalSubsidiaryData.length > 0 &&
                                  finalSubsidiaryData.map(
                                    (project_pnl, sub_index) => (
                                      <React.Fragment>
                                        <td>1000</td>
                                        <td>2000</td>
                                      </React.Fragment>
                                    )
                                  )}
                                <td>34555</td>
                                <td>445353</td>
                                <td>5657</td>
                                <td>6574534</td>
                                <td>434223</td>
                                <td>34342</td>
                              </tr>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="text-danger">
          {error_message || "Something went wrong!!!"}
        </p>
      )}
    </>
  );
};

const TableHeader = (props) => {
  const {
    currentCompany,
    subsiaryData,
    currentProjectName,
    browse_fs_ids,
    CompanyName,
  } = props;
  const colStyle = {
    width: "30%",

    textTransform: "capitalize",
  };
  const particlarTab = {
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem",
  };
  const noteCol = {
    minWidth: "100px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem",
  };

  const thBgColor = { backgroundColor: "#A2C0C2" };

  return (
    <thead>
      <tr style={thBgColor} key={getRandomKey()}>
        <th rowSpan="2" style={particlarTab}>
          <strong>Particulars </strong>
        </th>
        <th rowSpan="2" style={noteCol}>
          <strong>Notes</strong>
        </th>
        <th colSpan="2" style={colStyle}>
          <strong className="text-center" style={{ marginRight: "138px" }}>
            {currentProjectName}
          </strong>
        </th>

        {browse_fs_ids.length > 0 &&
          browse_fs_ids.map((subData, index) => (
            <th colSpan={2} style={colStyle}>
              <strong style={{ marginRight: "138px" }}>
                {CompanyName(subData) ?? "Name Not found"}
              </strong>
            </th>
          ))}
        <th colSpan="3" style={particlarTab}>
          <strong>ADJUSTMENT</strong>
        </th>
        <th colSpan="3" style={particlarTab}>
          <strong>TOTAL</strong>
        </th>
      </tr>

      <tr style={thBgColor} key={getRandomKey()}>
        <th style={{ minWidth: "132px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ minWidth: "132px" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        {subsiaryData.length > 0 &&
          subsiaryData.map((row, i) => (
            <>
              <th style={{ minWidth: "132px" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ minWidth: "132px" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
            </>
          ))}

        <th style={{ minWidth: "93px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{}}>OPENING</th>

        <th style={{ minWidth: "93px" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th>OPENING</th>
      </tr>
    </thead>
  );
};
