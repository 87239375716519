
export const applyAdjFilter = (copyAdjData, filterData, actionName) => {
    return dispatch => {
        const filteredData = copyAdjData.filter(obj => {
            return filterData.every(filter => {
                const { column, select, change, startAmount } = filter;
                let changes = change
    
                switch (column) {
                    case 'ledger_code':
                        if (select === 'is empty') { changes = "-" };
                        // return obj[Object.keys(obj)[0]].some(item => item[column] === changes);
                        const lowerCaseLedgerCode = changes.toLowerCase();
                        return obj[Object.keys(obj)[0]].some(item => item[column].toLowerCase() === lowerCaseLedgerCode);
    
                    case 'ledger_name':
                        if (select === 'is empty') { changes = "-" };
                        const lowerCaseChanges = changes.toLowerCase();
                        return obj[Object.keys(obj)[0]].some(item => item[column].toLowerCase().includes(lowerCaseChanges));
    
                    default:
                        if (select === 'Less than') {
                            return obj[Object.keys(obj)[0]].some(item => Number(item[column]) < Number(startAmount));
                        } else if (select === 'Greater than') {
                            return obj[Object.keys(obj)[0]].some(item => Number(item[column]) > Number(startAmount));
                        } else if (select === 'Equal to') {
                            return obj[Object.keys(obj)[0]].some(item => Number(item[column]) === Number(startAmount));
                        } else {
                            return true;
                        }
                }
            });
        });

        dispatch({ type: actionName, payload: filteredData });
    }
}