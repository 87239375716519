import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from "@mui/material";
import swal from "sweetalert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ShowLibraryReference({isShowRef, setIsShowRef}) {
const dispatch = useDispatch();
const { loading: bsLoading, isSuccess: bsSuccess, response: bsResponse, set_payload: bsSetPayload } = useSelector((state) => state.TempTabBSReducer);
const { loading: pnlLoading, isSuccess: pnlSuccess, response: pnlResponse, error_message, set_payload: pnlSetPayload } = useSelector((state) => state.TempTabPNLReducer);
const { isSuccess:cashflowSuccess, loading:cfLoading, response:csResponse } = useSelector((state)=>state.PFSCashFlow);
const reduxSocieData = useSelector((initialState) => initialState.SoccieReducer.standaloneSocieData);
const reduxDisclosureData = useSelector((initialState) => initialState.DisclosureReducer.disclosureData);
const BalanceSheets = bsResponse?.financial_statement ?? [];
const PnlSheets = pnlResponse?.pnl_statement ?? [];
const cashFlow = csResponse?.data || [];

  const SwitchReferenceTab = (element) => {
    const categories = [
        "Equity", "Financial assets", "Non-financial liabilities", 
        "Financial liabilities", "Non-financial Assets"
    ];
    const pnl_categories = [
        "Revenue from operations", "Other Income", "Expenses", 
        "Profit before exceptional items and tax", "Tax expense", 
        "Other Comprehensive Income", "Profit for the period from continuing operations", 
        "Profit before tax"
    ];
    const socie_categories =["previous_row_header","current_row_header"]

    // Search in BalanceSheets
    const foundInBalance = categories.some(category => 
        BalanceSheets[category]?.some(item => 
            item?.refData?.refNo === element || item?.refData?.refNo1 === element
        )
    );

    if (foundInBalance) {
        dispatch({ type: "NOTE_KEY", payload: 0 });
        return;
    }

    // Search in PnlSheets
    const foundInPnl = pnl_categories.some(category => 
        PnlSheets[category]?.some(item => 
            item?.refData?.refNo === element || item?.refData?.refNo1 === element
        )
    );

    if (foundInPnl) {
        dispatch({ type: "NOTE_KEY", payload: 1 });
        return;
    }

    // Search in CashFlow
    const foundInCashFlow = cashFlow.some(item =>
        item.faceValueData.some(items =>
            items?.refData?.refNo === element || items?.refData?.refNo1 === element
        )
    );
    if (foundInCashFlow) {
        dispatch({ type: "NOTE_KEY", payload: 2 });
        return;
    }
    // Search in Socie
    const foundInSocie = socie_categories.some(category => 
        reduxSocieData[category]?.some(item => 
            item?.refData?.refNo === element || item?.refData?.refNo1 === element
        )
    );

    if (foundInSocie) {
        dispatch({ type: "NOTE_KEY", payload: 3 });
        return;
    }
    // Search in Disclosure
    const foundInDisclosure = reduxDisclosureData?.find(category => 
        category?.disclosure_data?.some(item => 
            item?.sub_disclosure?.some(items =>
           items?.refData?.some(ele=>
            ele?.refNo === element)
           )
        )
    );
    if (foundInDisclosure) {
        dispatch({ type: "NOTE_KEY", payload: 7 });
        // dispatch({ type: "FILTER_DISCLOSURE_DATA", payload: foundInDisclosure});
        return;
    }

    swal("Warning", "No Reference Found!", "warning");
};



    return (
        <>
            <BootstrapDialog
                onClose={() => setIsShowRef({...isShowRef, isShow:false})}
                aria-labelledby="customized-dialog-title"
                open={isShowRef.isShow}
            >
                <div className='light-blue w-100 d-flex justify-content-between align-items-center'>
                    <DialogTitle sx={{ m: 0, p: 2, fontWeight:"600" }} id="customized-dialog-title">{isShowRef?.data?.reference}</DialogTitle>
                    <div>
                        <Button className='custom-common-theme-bg-color text-white mr-3 p-2' onClick={() => SwitchReferenceTab(isShowRef?.data?.reference) }> Reference in financial </Button>
                        <IconButton
                            aria-label="close"
                            onClick={() => setIsShowRef({...isShowRef, isShow:false})}
                            sx={{
                                color: (theme) => theme.palette.grey[800],
                                marginRight:"10px",
                                backgroundColor:"white",
                                borderRadius:"7px"
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent dividers>
                    {
                        isShowRef?.data?.libraryReference && isShowRef?.data?.libraryReference.length > 0 && isShowRef?.data?.libraryReference.map((text, ix) => (
                            <Typography gutterBottom key={ix}>{text}</Typography>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setIsShowRef({...isShowRef, isShow:false})}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}