const NCTTypes = {
    NCT_ACTION_START: "NCT_ACTION_START",
    NCT_FETCH_DATA: "NCT_FETCH_DATA",
    NCT_FETCH_DATA_FAILED: "NCT_FETCH_DATA_FAILED"
}

const Initial_state = {
    nctLoading: false,
    nct_error_message: null,
    nctIsSuccess: false,
    newColumns: [],
}

const NCTable = (state = Initial_state, action) => {
    switch (action.type) {
        case NCTTypes.NCT_ACTION_START:
            return {
                ...state,
                nctLoading: true,
                nct_error_message: null,
                nctIsSuccess: false,
                newColumns: []
            }
        case NCTTypes.NCT_FETCH_DATA:
            return {
                ...state,
                nctLoading: false,
                nct_error_message: null,
                nctIsSuccess: true,
                newColumns: action.payload.data?.column?.column
            }
        case NCTTypes.NCT_FETCH_DATA_FAILED:
            return {
                ...state,
                nctLoading: false,
                nct_error_message: action.payload.error,
                nctIsSuccess: false,
                newColumns: []
            }
        default:
            return state;
    }
};

export default NCTable;