import React from 'react'
import moment from "moment";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import "./linkRegister.css";
import CompanySVGComponent from '../../Components/Common/SVGComponents/CompanySVGComponent';


const LinkRegisterAccordion = ({ setSelectedItems, selectedItems, name, data, setDraggedData }) => {



    const handleCheckboxChange = (id) => {
        const isSelected = selectedItems.includes(id);

        if (isSelected) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleDragStart = (e, ele) => {
        if (selectedItems.length > 0) {
            // const filterSelectedData = data.filter(data_ele => selectedItems.includes(data_ele._id))
            // setDraggedData(selectedItems);
            const draggedItems = selectedItems.map(id => ({
                id,
                name, // Assuming `name` is consistent for all selected items
            }));
            setDraggedData(draggedItems);
        } else {
            // setDraggedData([ele._id]);
            setDraggedData([{ id: ele._id, name }]);
        }
    };

    return (
        <>
            <Accordion >
                <AccordionSummary
                    className="d-flex px-2 bg-white px-3"
                    expandIcon={<ArrowDropDownIcon />}

                >
                    <Typography className="custom-font-600 font-size-14 text-dark" >{name}</Typography>
                </AccordionSummary>
                <AccordionDetails className='custom-bg-white-150'>
                    {
                        data?.length > 0 ?
                            data.map(ele => (
                                <div
                                    key={ele._id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, ele,name)}
                                    className="d-flex mt-2 p-2 bg-white cursor-pointer border shadow radius-8px"
                                >
                                    <input
                                        className="m-2 cursor-pointer "
                                        type="checkbox"
                                        checked={selectedItems.includes(ele._id)}
                                        onChange={() => handleCheckboxChange(ele._id)}
                                    />
                                    <div>
                                        <p className="m-0 p-0 font-size-14 text-black font-inter font-weight-bold">{`${ele?.name}`}</p>
                                        <p className="m-0 p-0 font-size-12 text-secondary d-flex justify-content-center align-items-center custom-font-500 font-inter">
                                            <CompanySVGComponent color={"#ABA9A9"} width={11} height={15} />
                                            <span className='ml-1' >{ele?.name}</span>
                                            <CalendarMonthIcon className="ml-1" style={{ height: "15px" }} />
                                            <span className="ml-1">
                                                {
                                                    name === "Leases" ?
                                                        ele?.createdAt && moment(ele?.createdAt).format("MMMM DD YYYY")
                                                        : name === "Loans" ?
                                                            ele?.endDate && moment(ele?.endDate).format("MMMM DD YYYY"): 
                                                            ['Payable', 'Receivables'].includes(name) ?
                                                            ele?.asOnDate && moment(ele?.asOnDate).format("MMMM DD YYYY")
                                                            : ele?.createdAt && moment(ele?.createdAt).format("MMMM DD YYYY")
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))
                            : <p className="m-0 p-0 font-size-12 text-secondary"> {"not available"} </p>
                    }
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default LinkRegisterAccordion