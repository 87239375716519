import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../../assets/cross.svg";
import arrow from "../../../assets/arrow.svg";
import plus from "../../../assets/plus.svg";
import Select from "react-select";
import * as actionTypes from "../../../redux/actionTypes";
import { fetchAdjLogs, fetchDeletedAdj, fetchSAAdjustment } from "../../../redux/actions/actionStandaloneAdj";

const popup = {
  position: "Fixed",
  minHeight: "10rem",
  minWidth: "10rem",
  width: "66%",
  zIndex: "5",
  backgroundColor: "#F1F2F5",
  top: "7.5rem",
  left: "8%",
  borderRadius: "8px",
  boxShadow: "4px 6px 8px 0px #C0C0C060",
  padding: "20px",
  transform: "scale(0.9)",
};
const flexContainer = {
  display: "flex",
  alignItems: "center",
};
const close = {
  height: "43px",
  width: "43px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  borderRadius: "6px",
  fontWeight: "normal",
  marginRight: "15px",
};
const noClose = {
  height: "43px",
  width: "43px",
  backgroundColor: "#cacacabf",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  borderRadius: "6px",
  fontWeight: "normal",
  marginRight: "15px",
};
const field = {
  width: "25%",
  marginRight: "15px",
};
const contains = {
  width: "20%",
  marginRight: "15px",
};
const inputs = {
  width: "33%",
  marginRight: "15px",
};
const input = {
  height: "46px",
  border: "1px solid #cacacabf",
  padding: "10px",
  borderRadius: "6px",

  ":focusVisible": {
    outline: "none",
    border: "2px solid #03565A",
  },
};

const search = {
  width: "85px",
  height: "46px",
  backgroundColor: "#03565A",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
};
const noSearch = {
  width: "46px",
  height: "46px",
  backgroundColor: "#03565a98",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
};

const addFilter = {
  marginTop: "1rem",
  color: "#03565A",
  fontSize: "1.1rem",
  fontWeight: "600",
  display: "flex",
  alignItems: "center",
};

const text = {
  fontWeight: "500",
  fontSize: "1rem",
  color: "#696F79",
};

const textBox = {
  marginRight: "15px",
  width: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const columnName = [
  { value: "ledger_code", label: "LEDGER CODE" },
  { value: "ledger_name", label: "LEDGER NAME" },
  // { value: 'branch', label: 'BRANCH' },
  // { value: 'PREVIOUS YEAR', label: 'PREVIOUS YEAR' },
  { value: "net_adjusted_amount", label: "FINAL AMT" },
  { value: "cy", label: "CURRENT AMT" },
  { value: "py", label: "PREVIOUS AMT" },
  { value: "adjusted_amount", label: "ADJUSTMENT AMT" },

  // { value: 'FS GROUPING', label: 'FS GROUPING' },
  // { value: 'NOTES GROUPING', label: 'NOTES GROUPING' },
  // { value: 'SUB GROUPING', label: 'SUB GROUPING' },
];
const type = [
  { value: "Contains", label: "Contains" },
  { value: "is empty", label: "Is empty" },
];

const finalAmountOptions = [
  { value: "Less than", label: "Less than" },
  { value: "Greater than", label: "Greater than" },
  { value: "Equal to", label: "Equal to" },
];

export default function AdjustmentFilter({
  actionFrom,
  show,
  setObject,
  setFilterShow,
  filterRow,
  setFilterRow,
  handleApplyFilter,
  SetConfirmFilClose,
  setstate,
  state,
}) {
  const dispatch = useDispatch();
  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id")
  
  const [render, setRender] = useState("");
  const filter = useSelector((initialState) => initialState.reducer.filter);
  const [isFilter, setFilter] = useState(false);


  // useEffect(() => {
  //   setstate(filter);
  // }, []);


  const handleFilter = () => {
    dispatch({ type: actionTypes.SET_FILTER, payload: state });

    setFilterShow(false);
    handleApplyFilter();
  };

  const handleChange = (field, i, e) => {
    if (
      field === "change" ||
      field === "startAmount" ||
      field === "endAmount"
    ) {
      let search = state;
      search[i][field] = e.target.value;
      setstate(search);
      dispatch({ type: actionTypes.SET_FILTER, payload: search });
      setRender(Math.random());
    } else {
      let temp = state;
      temp[i][field] = e.value;
      if (field === "select" && e.value === "is empty") {
        temp[i]["change"] = "";
      }
      setstate(temp);
      setRender(Math.random());
    }
  };
  useEffect(() => {
    setObject(state);
    setRender(Math.random());
    dispatch({ type: actionTypes.SET_FILTER, payload: state });
  }, [state]);

  function handleDelete(i) {
    const temp = state;

    const newarr = temp.filter((val, ix) => ix !== i);
    setstate(newarr);
    setFilter(false);
    dispatch({ type: actionTypes.SET_FILTER, payload: newarr });
    if (newarr.length === 0) {
      if (actionFrom === "log") {
        dispatch(fetchAdjLogs(project_id, tb_id));
      } else if (actionFrom === "deleted_adj") {
        dispatch(fetchDeletedAdj(project_id, tb_id));
      } else {
        dispatch(fetchSAAdjustment(project_id, tb_id));
      }
      setFilterShow(false);
    }
  }

  function handleNew() {
    setstate((oldArray) => [
      ...oldArray,
      { column: "", select: "", change: "", startAmount: "", endAmount: "" },
    ]);
    setFilter(true);
    dispatch({ type: actionTypes.SET_FILTER, payload: [] });
  }


  if (!show) return null;

  return (
    <>
      <div style={popup}>
        {state.map((val, i) => {
          return (
            <div style={flexContainer} key={i}>
              {i === state.length - 1 ? (
                <div
                  style={close}
                  onClick={() => {
                    handleDelete(i);
                  }}
                >
                  <img src={cross} style={{ transform: "scale(0.75)" }} />
                </div>
              ) : (
                <div style={noClose}>
                  <img src={cross} style={{ transform: "scale(0.75)" }} />
                </div>
              )}
              {i === 0 ? (
                <div style={textBox}>
                  <p className="text-dark" style={text}>Where</p>
                </div>
              ) : (
                <div style={textBox}>
                  <p className="text-dark" style={text}>as</p>
                </div>
              )}

              <div style={field}>
                <Select
                  value={columnName.filter(function (option) {
                    return option.value === val.column;
                  })}
                  options={columnName}
                  onChange={(e) => handleChange("column", i, e)}
                  placeholder="Field"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    colors: {
                      ...theme.colors,
                      backgroundColor: "white",
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                  })}
                />
              </div>

              {val.column === "ledger_name" || val.column === "ledger_code" ? (
                <React.Fragment>
                  <div style={contains}>
                    <Select
                      value={type.filter(function (option) {
                        return option.value === val.select;
                      })}
                      options={type}
                      onChange={(e) => handleChange("select", i, e)}
                      placeholder="Select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "6px",
                        colors: {
                          ...theme.colors,
                          backgroundColor: "white",
                          primary25: "#03565a98",
                          primary: "#03565A",
                        },
                      })}
                    />
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {val.column !== "ledger_code" && val.column !== "ledger_name" && (
                <React.Fragment>
                  <div style={contains}>
                    <Select
                      value={finalAmountOptions.filter(function (option) {
                        return option.value === val.select;
                      })}
                      options={finalAmountOptions}
                      onChange={(e) => handleChange("select", i, e)}
                      placeholder="Select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "6px",
                        colors: {
                          ...theme.colors,
                          backgroundColor: "white",
                          primary25: "#03565a98",
                          primary: "#03565A",
                        },
                      })}
                    />
                  </div>
                </React.Fragment>
              )}

              {val.column === "ledger_code" || val.column === "ledger_name" ? (
                <div style={inputs}>
                  {val.select === "is empty" ? (
                    <input
                      style={input}
                      value={val.change}
                      disabled={true}
                      onChange={(e) => handleChange("change", i, e)}
                    />
                  ) : (
                    <input
                      style={input}
                      value={val.change}
                      onChange={(e) => handleChange("change", i, e)}
                    />
                  )}
                </div>
              ) : (
                ""
              )}

              {val.column !== "ledger_code" &&
                val.column !== "ledger_name" &&
                val.select === "Equal to" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : val.column !== "ledger_code" &&
                val.column !== "ledger_name" &&
                val.select === "Greater than" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : val.column !== "ledger_code" &&
                val.column !== "ledger_name" &&
                val.select === "Less than" ? (
                <React.Fragment>
                  <div style={inputs}>
                    <input
                      placeholder="Amount"
                      name=""
                      value={val.startAmount}
                      style={input}
                      onChange={(e) => handleChange("startAmount", i, e)}
                    ></input>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {i === state.length - 1 ? (
                <div style={search} onClick={handleFilter}>
                  <img src={arrow} />
                </div>
              ) : (
                <div style={noSearch} onClick={(e) => e.preventDefault}>
                  <img src={arrow} />
                </div>
              )}
            </div>
          );
        })}


        {true && (
          <div style={addFilter}>
            <img
              alt="plus"
              src={plus}
              style={{ marginRight: "5px" }}
              onClick={() => {
                handleNew();
              }}
            />
            Add Filter
          </div>
        )}
      </div>
    </>
  );
}
