import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import arrowLeftGreen from "../../assets/arrowLeftGreen.svg";
import { Navbar } from "../../Components/Navbar";
import { Submit } from "../../Components/Button";
import PreviewCard from "../../Components/populateFs/PreviewCard";
import "./preview.css";
import ExportExcel from "../../Components/excelExport/ExportExcel";
import { getFS, saveCOA } from "../../services/api/StandAlone/preview";
import swal from "sweetalert";
import DisclosureCreateModal from "../../Components/Common/Moadel/DisclosureCreateModal";
import Loading from "../../Components/Common/Loader/Loading";

const main = {
  padding: "1rem 4rem",
  minWidth: "1045px",
  backgroundColor: "#E5E5E5",
  minHeight: "calc(100vh - 80px)",
};

const submitExport = {
  display: "flex",
  alignItems: "center",
  padding: "0 3rem",
};
const exportText = {
  marginLeft: "auto",
  color: "#03565A",
  fontSize: "1rem",
  fontWeight: "600",
  width: "5rem",
};
const button = {
  marginLeft: "2rem",
};

const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};


const Preview = () => {
  const history = useHistory();
  const [classification, setClassification] = useState({
    loading: false,
    isSuccess: false,
    response: [],
    error_message: null
  });
  const [projectName, setProjectName] = useState('')
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const classification_fs = Object.keys(classification.response);

  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id");

  useEffect(() => {
    (async () => {
      await fetchFsClassification();
    })()
  }, []);

  const fetchFsClassification = async () => {
    if (!classification.isSuccess) {
      try {
        setClassification((res) => {
          return {
            ...res,
            loading: true
          }
        });
        const apiResponse = await getFS(project_id, tb_id);
        setClassification((res) => {
          return {
            ...res,
            loading: false,
            isSuccess: true,
            response: apiResponse?.data?.classification_list
          }
        })
        setProjectName(apiResponse.data?.project?.project_name);
      } catch (error) {
        setClassification((res) => {
          return {
            ...res,
            loading: false,
            isSuccess: false,
            error_message: error.message
          }
        })
      }
    }
  }

  const SaveCOA = (name) => {
    setLoader(true);
    saveCOA(project_id, { name }).then(res => swal("Success", res.data?.message, "success").then(res => { setLoader(false); setOpen(false) }))
      .catch(err => swal("Error", err.response?.data?.error, "error").then(res => { setLoader(false); setOpen(false) }));
  }

  return (
    <>
      <Navbar text="Classification Preview" />
      <div style={main}>
        <div style={submitExport}>
          <div style={{ color: "#03565A", display: "flex" }}>
            <div
              style={backNav}
              onClick={() => {
                history.push(`/templateV2/${project_id}`);
              }}
            >
              <img src={arrowLeftGreen} style={{ marginRight: "10px" }} />
            </div>
            <h2 style={{ fontSize: "1.5rem", fontWeight: "700" }}>
              Classification Preview
            </h2>
          </div>
          <div style={exportText}>
            <ExportExcel projectName={projectName} />
          </div>
          <div style={button} onClick={() => setOpen(true)}>
            <Submit value="Save COA" sm />
          </div>
          <div style={button} onClick={() => history.push("/financialstatement")}>
            <Submit value="Confirm Grouping" sm />
          </div>
        </div>
        {classification.loading ?
          <Loading />
          : classification.error_message ? <div className="text-danger text-center" >{classification.error_message}</div> :
            <div id="gridScroll" className="grid">
              {classification_fs.map((dat, ix) => {
                return (
                  <PreviewCard name={dat} data={classification.response[dat]} key={ix} />
                );
              })}
            </div>
        }
      </div>

      {
        open && <DisclosureCreateModal open={open} setOpen={setOpen}
          title={"Chart Of Accounts"}
          inpName={"COA Name"}
          btnName={"Save"}
          HandleSubmit={SaveCOA}
          loading={loader}
        />
      }
    </>
  );
};

export default Preview;
