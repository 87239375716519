import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../services/socket/socket";
import * as actionTypes from "../../redux/actionTypes";
import { Spinner } from "react-bootstrap";
import { motion } from "framer-motion";

import Note1 from "./Note1";

import axios from "axios";
import { Checkbox } from "@mui/material";
import { formatIndianCurrency } from "../../helper/ConvertValue";

const inputDiv = {
  height: "100%",
  width: "100%",
};
const input = {
  height: "100%",
  width: "100%",
  border: "none",
  textAlign: "end",
  background: "transparent",
};

const level3 = {
  color: "black",
  fontSize: "18px",
  fontWeight: "500",
  borderTop: "none",
};

const NoteEquiteShareCaptial = (props) => {
  const finalStr = props.FinalStr;
  const NoteStr = props.NoteStr;
  const dispatch = useDispatch();
  const [note, setNote] = useState([]);
  const [dummy, setDummy] = useState(props.note4);
  const [nid, setNld] = useState("");
  const [noteOpen, setNoteOpen] = useState(false);
  const [equityShareValues, setEquityShareValues] = useState({
    restated_balance_at_the_beginning_current_previous_reporting_period_cy : 0,
    restated_balance_at_the_beginning_current_previous_reporting_period_py : 0,
    balance_at_the_end_current_previous_reporting_period_cy : 0,
    balance_at_the_end_current_previous_reporting_period_py : 0,
    sub_cy:0,
    sub_py:0
  });

  const TB_ID = useSelector((initialState) => initialState.reducer.tb_id) || localStorage.getItem('tb_id');
  const Note = useSelector(
    (initialState) => initialState.reducerNotes[NoteStr]
  );
  const project_id = localStorage.getItem("project_id");
  useEffect(() => {
    const auth = localStorage.getItem("auth_token");
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
    };
    const fd = new FormData();
    

    fd.append("notes_no", props.note);
    axios
      .post(`api/v1/notes/gcn/${project_id}/${TB_ID}`, fd, { headers })
      .then((res) => {
        // console.log(props.note, "here is props.note");
        if (props.note === "13(c)") {
         
          setNld(res.data.notes.nlid);
          setNote(res.data.notes.data[1].sub_grp);
          dispatch({
            type: actionTypes[finalStr],
            payload: res.data.notes.data[1].sub_grp,
          });
        }
       
        setNld(res.data.notes.nlid);
        setNote(res.data.notes.data[0].sub_grp);
       
        dispatch({
          type: actionTypes[finalStr],
          payload: res.data.notes.data[0].sub_grp,
        });
      })
      .catch((err) => {
        // console.log(err.response)
        // if(err.response.data){
        //   alert(err.response.data.error)
        // }
      });
    return () => {
      // dispatch({type: actionTypes.SET_NOTE4,payload:note})
    };
  }, []);


  function handleChange(e, data,a, field,index) {
    const content = {};

    content.sid = `${a.sid}`;
    content.index = index;

    if (field === "currentYear") {
      content.cy_amt = parseFloat(e.target.value);
      content.py_amt = "0";
      // if (e.target.value === "") {
      //   content.cy_amt = "0";
      // }
      if (data.py_amt) {
        content.py_amt = data.py_amt;
      }
    }
    if (field === "prevYear") {
      content.py_amt = parseFloat(e.target.value);
      content.cy_amt = "0";
      // if (e.target.value === "") {
      //   content.py_amt = "0";
      // }
      if (data.cy_amt) {
        content.cy_amt = data.cy_amt;
      }
    }

    // var arr = note;
    // arr.map((line) => {
    //   if (line.sid === a.sid) {
    //     console.log(line, "sub here");
    //     line.sub_grp.cy_amt = Number(content.cy_amt)
    //     line.sub_grp.py_amt = Number(content.py_amt)
    //   }
    // });
    
    // setNote(arr);

    // socket.emit("notes-auto-save", {
    //   project_id: `${project_id}`,
    //   tb_id: `${TB_ID}`,
    //   nlid: `${nid}`,
    //   contents: content,
    // });
    content.note_no = props.note;
    let arr = [...note];
    arr.forEach((element,i) => {

      if(i === index){
        element.sub_grp.cy_amt = content.cy_amt
        element.sub_grp.py_amt = content.py_amt
      }
      
    });

    setNote(arr)

    socket.emit("notes-auto-save-equity-share-captial", {
      project_id: `${project_id}`,
      tb_id: `${TB_ID}`,
      nlid: `${nid}`,
      contents: content,
    });

  }


  

  useEffect(() => {

    if(note.length > 0){
      let sub_py = note.reduce(
        (totalpy, i) => totalpy + Number(i.sub_grp.py_amt),
        0
      );
      let sub_cy = note.reduce(
        (totalCy, i) => totalCy + Number(i.sub_grp.cy_amt),
        0
      );

      let copyNote = [...note].splice(0,2);
      let cy_0_1 = copyNote.reduce(
        (totalpy, i) => totalpy + Number(i.sub_grp.cy_amt),
        0
      );
      let py_0_1 = copyNote.reduce(
        (totalCy, i) => totalCy + Number(i.sub_grp.py_amt),
        0
      );

      let copyNote1 = [...note].splice(0,3);
      let cy_0_2 = copyNote1.reduce(
        (totalpy, i) => totalpy + Number(i.sub_grp.cy_amt),
        0
      );
      let py_0_2 = copyNote1.reduce(
        (totalCy, i) => totalCy + Number(i.sub_grp.py_amt),
        0
      );

      setEquityShareValues({...equityShareValues, 
        restated_balance_at_the_beginning_current_previous_reporting_period_cy :cy_0_1,
        restated_balance_at_the_beginning_current_previous_reporting_period_py:  py_0_1,
        sub_cy:sub_cy,
        sub_py:sub_py,
        balance_at_the_end_current_previous_reporting_period_cy:cy_0_2,
        balance_at_the_end_current_previous_reporting_period_py:py_0_2
      });
    }
    
  }, [note])
  

  if ((note == null) | (note == [])) {
    return (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" />
        <Spinner animation="grow" size="sm" />
      </div>
    );
  }

  return (
    <div>
      <table>
        <thead>
          <tr style={{ backgroundColor: "#A2C0C2" }}>
            <th
              style={{
                width: "40%",
                height: "90px",
                textAlign: "left",
                paddingLeft: "2rem",
              }}
            >
              Particulars
            </th>
            <th style={{ width: "10%" }}>As at March 31, 2021</th>
            <th style={{ width: "10%" }}>As at March 31, 2020</th>
            <th style={{ width: "10%" }}>References</th>
            <th style={{ width: "10%" }}>IND AS References</th>
            <th style={{ width: "10%" }}>Checklist References</th>
            <th
              style={{ width: "10%", textAlign: "left", paddingLeft: "3rem" }}
            >
              Guidances
            </th>
          </tr>
        </thead>
        <tbody>
            {/* Current reporting period */}
            {/* <tr style={level3}>
                        <td
                        style={{
                            width: "40%",
                            height: "70px",
                            textAlign: "left",
                            paddingLeft: "2rem",
                            backgroundColor: "#DADADA",
                            fontWeight:"700"
                        }}
                        >
                        Current reporting period
                        </td>
                        <td
                        style={{
                            width: "10%",
                            textAlign: "end",
                            paddingRight: "1rem",
                            backgroundColor: "#DADADA",
                        }}
                        >
                        </td>
                        <td
                        style={{
                            width: "10%",
                            textAlign: "end",
                            paddingRight: "1rem",
                            backgroundColor: "#DADADA",
                        }}
                        >
                        </td>
                        <td
                        style={{
                            width: "25%",
                            textAlign: "right",
                            paddingLeft: "3rem",
                            backgroundColor: "#DADADA",
                        }}
                        ></td>
            </tr> */}
            {note.map((sub, i) => {
                    return (
                      <>
                        <tr key={i} style={level3}>
                            <td
                            style={{
                                width: "40%",
                                height: "70px",
                                textAlign: "left",
                                paddingLeft: "2rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {sub.sub_grp.sub_grp === "Equity share capital" ? "Balance at the beginning of the current/previous reporting period" : sub.sub_grp.sub_grp}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              {
                                i === 0 ? sub.sub_grp.cy_amt.toLocaleString("en-IN") : (
                                  <>
                                    {/* <input
                                      type="number"
                                      value={sub.sub_grp.cy_amt}
                                      style={input}
                                      onChange={(e) => {
                                        handleChange(e,sub.sub_grp, sub, "currentYear", i);
                                      }}
                                      onBlur={() => {}}
                                    /> */}
                                    <input
                                      type="text"
                                      value={formatIndianCurrency(sub.sub_grp.cy_amt)}
                                      style={input}
                                      onChange={(e) => {const value = e.target.value.replace(
                                        /[^0-9.-]/g,
                                        ""
                                      ); handleChange({
                                        ...e,
                                        target: {
                                          ...e.target,
                                          value: Number(value),
                                        },
                                      },sub.sub_grp, sub, "currentYear", i);
                                      }}
                                      onBlur={() => {}}
                                    /> 
                                  </>
                                )
                              }
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              {
                                i === 0 ? sub.sub_grp.py_amt.toLocaleString("en-IN") : (
                                  <>
                                    {/* <input
                                      type="number"
                                      value={sub.sub_grp.py_amt}
                                      style={input}
                                      onChange={(e) => {
                                        handleChange(e,sub.sub_grp, sub, "prevYear", i);
                                      }}
                                      onBlur={() => {}}
                                    /> */}
                                    <input
                                      type="text"
                                      value={formatIndianCurrency(sub.sub_grp.py_amt)}
                                      style={input}
                                      onChange={(e) => {const value = e.target.value.replace(
                                        /[^0-9.-]/g,
                                        ""
                                      ); handleChange({
                                        ...e,
                                        target: {
                                          ...e.target,
                                          value: Number(value),
                                        },
                                      },sub.sub_grp, sub, "prevYear", i);
                                      }}
                                      onBlur={() => {}}
                                    />
                                  </>
                                )
                              }
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "right",
                                paddingLeft: "3rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            ></td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "center",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              <input 
                                type="checkbox" 
                              />
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "center",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              <input 
                                type="checkbox" 
                              />
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "right",
                                paddingLeft: "3rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            ></td>
                        </tr>
                        {
                          i === 1 && (
                            <tr key={i} style={level3}>
                              <td
                              style={{
                                  width: "40%",
                                  height: "70px",
                                  textAlign: "left",
                                  paddingLeft: "2rem",
                                  backgroundColor: "#EBEBEB",
                              }}
                              >
                                {"Restated balance at the beginning of the current/previous reporting period"}
                              {/* {sub.sub_grp.sub_grp === "Equity share capital" ? "Balance at the beginning of the current/previous reporting period" : sub.sub_grp.sub_grp} */}
                              </td>
                              <td
                              style={{
                                  width: "10%",
                                  textAlign: "end",
                                  paddingRight: "1rem",
                                  backgroundColor: "#EBEBEB",
                              }}
                              >
                              {/* {sub.sub_grp.cy_amt.toLocaleString("en-IN")} */}
                              {equityShareValues.restated_balance_at_the_beginning_current_previous_reporting_period_cy.toLocaleString("en-IN")}
                              
                              </td>
                              <td
                              style={{
                                  width: "10%",
                                  textAlign: "end",
                                  paddingRight: "1rem",
                                  backgroundColor: "#EBEBEB",
                              }}
                              >
                              {/* {sub.sub_grp.py_amt.toLocaleString("en-IN")} */}
                              {equityShareValues.restated_balance_at_the_beginning_current_previous_reporting_period_py.toLocaleString("en-IN")}
                              </td>
                              <td
                              style={{
                                  width: "10%",
                                  textAlign: "right",
                                  paddingLeft: "3rem",
                                  backgroundColor: "#EBEBEB",
                              }}
                              ></td>
                               <td
                              style={{
                                  width: "10%",
                                  textAlign: "center",
                                  backgroundColor: "#EBEBEB",
                              }}
                              >
                                <input 
                                  type="checkbox" 
                                />
                              </td>
                               <td
                              style={{
                                  width: "10%",
                                  textAlign: "center",
                                  backgroundColor: "#EBEBEB",
                              }}
                              >
                               <input 
                                type="checkbox" 
                              />
                              </td>
                               <td
                              style={{
                                  width: "10%",
                                  textAlign: "center",
                                  backgroundColor: "#EBEBEB",
                              }}
                              ></td>
                            </tr>
                          )
                        }
                        {
                          i === 2 && (
                            <tr key={i} style={level3}>
                            <td
                            style={{
                                width: "40%",
                                height: "70px",
                                textAlign: "left",
                                paddingLeft: "2rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              {"Balance at the end of the current/previous reporting period"}
                            {/* {sub.sub_grp.sub_grp === "Equity share capital" ? "Balance at the beginning of the current/previous reporting period" : sub.sub_grp.sub_grp} */}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {/* {sub.sub_grp.cy_amt.toLocaleString("en-IN")} */}
                            {equityShareValues.balance_at_the_end_current_previous_reporting_period_cy.toLocaleString("en-IN")}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {/* {sub.sub_grp.py_amt.toLocaleString("en-IN")} */}
                            {equityShareValues.balance_at_the_end_current_previous_reporting_period_py.toLocaleString("en-IN")}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "right",
                                paddingLeft: "3rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            ></td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "center",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              <input 
                                type="checkbox" 
                              />
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "center",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                              <input 
                                type="checkbox" 
                              />
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "right",
                                paddingLeft: "3rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            ></td>
                        </tr>
                          )
                        }
                      </>
                    );
            })}

            {/* Previous reporting period */}
            {/* <tr style={level3}>
                        <td
                        style={{
                            width: "40%",
                            height: "70px",
                            textAlign: "left",
                            paddingLeft: "2rem",
                            backgroundColor: "#DADADA",
                            fontWeight:"700"
                        }}
                        >
                        Previous reporting period
                        </td>
                        <td
                        style={{
                            width: "10%",
                            textAlign: "end",
                            paddingRight: "1rem",
                            backgroundColor: "#DADADA",
                        }}
                        >
                        </td>
                        <td
                        style={{
                            width: "10%",
                            textAlign: "end",
                            paddingRight: "1rem",
                            backgroundColor: "#DADADA",
                        }}
                        >
                        </td>
                        <td
                        style={{
                            width: "25%",
                            textAlign: "right",
                            paddingLeft: "3rem",
                            backgroundColor: "#DADADA",
                        }}
                        ></td>
            </tr>
            {note.map((sub, i) => {
                if(i > 4){
                    const py = sub.disclosures.reduce(
                        (totalPy, i) => totalPy + Number(i.py_amt),
                        0
                    );
                    const cy = sub.disclosures.reduce(
                        (totalCy, i) => totalCy + Number(i.cy_amt),
                        0
                    );
                    
                    return (
                        <tr key={i} style={level3}>
                            <td
                            style={{
                                width: "40%",
                                height: "70px",
                                textAlign: "left",
                                paddingLeft: "2rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {sub.sub_grp.sub_grp}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {sub.sub_grp.cy_amt.toLocaleString("en-IN")}
                            </td>
                            <td
                            style={{
                                width: "10%",
                                textAlign: "end",
                                paddingRight: "1rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            >
                            {sub.sub_grp.py_amt.toLocaleString("en-IN")}
                            </td>
                            <td
                            style={{
                                width: "25%",
                                textAlign: "right",
                                paddingLeft: "3rem",
                                backgroundColor: "#EBEBEB",
                            }}
                            ></td>
                        </tr>
                    );
                }
            })} */}

            <tr
            style={{
                height: "50px",
                backgroundColor: " #F0DBC8",
                padding: "10px 0 0 2rem",
                alignContent: "center",
                fontSize: "1.1rem",
                fontWeight: "600",
            }}
            >
            <td
                style={{
                width: "40%",
                height: "33px",
                textAlign: "left",
                paddingLeft: "2rem",
                }}
            >
                Total amount
            </td>
            <td style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}>
                {equityShareValues.sub_cy.toLocaleString("en-IN")}
            </td>
            <td style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}>
                {equityShareValues.sub_py.toLocaleString("en-IN")}
            </td>
            <td
                style={{ width: "10%", textAlign: "left", paddingLeft: "3rem" }}
            ></td>
            <td
                style={{ width: "10%", textAlign: "left", paddingLeft: "3rem" }}
            ></td>
            <td
                style={{ width: "10%", textAlign: "left", paddingLeft: "3rem" }}
            ></td>
            <td
                style={{ width: "10%", textAlign: "left", paddingLeft: "3rem" }}
            ></td>
            </tr>
        </tbody>
      </table>
      <br />

      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          height: "50px",
          border: "2px solid #e8e8e8",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "1rem",
            color: "grey",
          }}
        >
          <p>Notes here</p>
        </div>
        <div
          onClick={(e) => {
            setNoteOpen(!noteOpen);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "3rem",
            background: "#e8e8e8",
            marginLeft: "auto",
            marginRight: "2rem",
            cursor: "pointer",
          }}
        >
          <i className="fas fa-pencil-alt" /> {/* <img src/> */}
        </div>
      </div>
      {noteOpen && (
        <motion.div
          style={{
            marginBottom: "1rem",
            padding: "0rem",
            backgroundColor: "white",
            border: "2px solid #e8e8e8",
            borderTop: "none",
          }}
        >
          {/* <h1>note 3  </h1> */}
          <Note1 color="grey" />
        </motion.div>
      )}
      {/* <div >
          Net Carrying Amount</div> */}
    </div>
  );
};

export default NoteEquiteShareCaptial;
