import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
//sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
//redux
import { combineReducers,createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './redux/reducer/reducer';
import reducerNotes from './redux/reducer/reducerNotes';
import reducerTemplate from './redux/reducer/reducerTemplate';
import BSReducer from "./redux/reducer/reducerBS";
import PNLReducer from "./redux/reducer/reducerPNL";
import ADJReducer from "./redux/reducer/reducerAdjustment";
import ADJStandaloneReducer from "./redux/reducer/reducerStandaloneAdj";
import SGReducer from "./redux/reducer/reducerSG";
import SoccieReducer from "./redux/reducer/reducerSocie";
import PADJReducer from "./redux/reducer/reducerPreAdjustment";
import APAReducer from "./redux/reducer/reducerAutoPopulation";
import GroupingReducer from "./redux/reducer/reducerGrouping";
import WSReducer from './redux/reducer/workSpace/reducerWorkspace';
import TempTabBSReducer from './redux/reducer/TemplateTabs/reducerBS';
import TempTabPNLReducer from './redux/reducer/TemplateTabs/reducerPNL';
import ConventionModeReducer from './redux/reducer/TemplateTabs/reducerCM';
import PCReducer from './redux/reducer/reducerPresetCheck';
import PresetReducer from './redux/reducer/reducerPreset';
import DisclosureChecklistReducer from './redux/reducer/reducerDisclosureChecklist';
import CoaReducer from './redux/reducer/reducerCOA';
import IRReducer from './redux/reducer/reducerIr';
import TTGroupingReducer from './redux/reducer/TemplateTabs/reducerGrouping';
import selectedDragReducer from './redux/reducer/TemplateTabs/reducerSelectDrag';
import ASSOCIATEReducer from './redux/reducer/reducerAssociate';
import StandaloneGroupingReducer from './redux/reducer/reducerStandaloneGrouping';
import CompanyReducer from './redux/reducer/workSpace/reducerCompany';
import LeaseReducer from './redux/reducer/workSpace/reducerLease';
import LoansReducer from './redux/reducer/workSpace/reducerLoans';
import ReceivablesReducer from './redux/reducer/workSpace/reducerReceivables';
import PayablesReducer from './redux/reducer/workSpace/reducerPayables';
import AllPresetsReducer from './redux/reducer/workSpace/reducerAllPresets';
import WSSettingReducer from './redux/reducer/workSpace/reducerWorkspaceSetting';
import CNReducer from './redux/reducer/reducerCN';
import AllWSReducer from './redux/reducer/workSpace/reducerAllWorkspace';
import PrepareConsoleReducer from './redux/reducer/reducerPrepareConsole';
import SubsidiaryConsoleReducer from './redux/reducer/reducerSubsidiaryData';
import miscellaneousReducer from './redux/reducer/reducerMiscellaneous';
import WorkspacePrepareconsolReducer from './redux/reducer/reducerWorkspacePrepareConsol';
import TempTabNotesReducer from './redux/reducer/TemplateTabs/reducerNotes';
import HandlePopulateFsNotesReducer from './redux/reducer/reducerHandlePopulateFsNotes';
import selectedDragRow from './redux/reducer/TemplateTabs/reducerDragStore';
import DisclosureReducer from './redux/reducer/reducerDisclosure';
import CashflowReducer from './redux/reducer/reducerCashflow';
import PFSCashFlow from './redux/reducer/PopulateFs/reducerCashflow';
import LibraryReducer from './redux/reducer/reducerLibrary';
import NotesSoftDelete from './redux/reducer/TemplateTabs/reducerDN';
import BookClosureReducer from './redux/reducer/BookClosure/reducerBookClosure';
import AuthReducer from './redux/reducer/reducerAuth';
import SupportReducer from './redux/reducer/reducerSupport';
import BookClosureTbcReducer from './redux/reducer/BookClosure/reducerBookClosureTBC';
import AllOtherDetailsReducer from './redux/reducer/InvestmentRegister/reducerAllOthersData';
import BookClosurePresetReducer from './redux/reducer/BookClosure/reducerBCPreset';
import AdditionalOtherDetailsReducer from './redux/reducer/InvestmentRegister/reducerAdditionalOthersData';
import NCTable from './redux/reducer/TemplateTabs/reducerNewColumns';

const rootReducer = combineReducers({
  reducer,
  reducerNotes,
  reducerTemplate,
  BSReducer,
  PNLReducer,
  ADJReducer,
  ADJStandaloneReducer,
  SGReducer,
  SoccieReducer,
  PADJReducer,
  APAReducer,
  GroupingReducer,
  WSReducer,
  TempTabBSReducer,
  TempTabPNLReducer,
  ConventionModeReducer,
  PCReducer,
  PresetReducer,
  DisclosureChecklistReducer,
  CoaReducer,
  selectedDragReducer,
  IRReducer,
  TTGroupingReducer,
  ASSOCIATEReducer,
  StandaloneGroupingReducer,
  CompanyReducer,
  LeaseReducer,
  LoansReducer,
  ReceivablesReducer,
  PayablesReducer,
  AllPresetsReducer,
  CNReducer,
  AllWSReducer,
  PrepareConsoleReducer,
  SubsidiaryConsoleReducer,
  miscellaneousReducer,
  WorkspacePrepareconsolReducer,
  TempTabNotesReducer,
  HandlePopulateFsNotesReducer,
  selectedDragRow,
  DisclosureReducer,
  CashflowReducer,
  PFSCashFlow,
  LibraryReducer,
  WSSettingReducer,
  NotesSoftDelete,
  BookClosureReducer,
  BookClosureTbcReducer,
  BookClosurePresetReducer,
  SupportReducer,
  AuthReducer,
  AllOtherDetailsReducer,
  AdditionalOtherDetailsReducer,
  NCTable,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
store.subscribe(() => store.getState())


ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter >
        <App />
      </BrowserRouter >
    </Provider>
,
  document.getElementById('root')
);
