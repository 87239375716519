import React, { useState, useEffect, useContext } from "react";
import { useTable, useRowSelect } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../multiSelect/MultiSelect";
import { columnBaseTable } from "./column";

import "./basicTable.css";
import { Spinner} from "react-bootstrap";
import { ThemeContext } from "../../helper/DarkModeContext";
import { fetchConventionalMode, updateConventionalMode } from "../../redux/actions/TemplateTabs/actionCM";
import { storeDragSelected, storeDragMultiSelected } from "../../redux/actions/TemplateTabs/actionDragSelector";
import { fetchColumns } from "../../redux/actions/TemplateTabs/actionNewColumns";

const BasicTable = (props) => {
  const { hideItems, filterEnable } = props;
  const dispatch = useDispatch();
  const R_tb_id = useSelector((initialState) => initialState.reducer.tb_id);
  const LOCAL_tb_id = localStorage.getItem("tb_id");
  let tb_id = R_tb_id ? R_tb_id : LOCAL_tb_id;

  const conventionModeData = useSelector(
    (state) => state.ConventionModeReducer
  );

  const {nctLoading, nct_error_message, nctIsSuccess, newColumns} = useSelector( (state) => state.NCTable );

  const [conventionalData, setConventionalData] = useState([]);
  const groupData = useSelector((state) => state.TTGroupingReducer);
  const {checkYearly} = useSelector((state) => state.selectedDragReducer);
  const groupDataRes = groupData.response ?? [];
  props.setTotalRow(conventionalData);

  const [selected, setSelected] = useState("");
  const [highlight, setHighlight] = useState(false);
  const { theme } = useContext(ThemeContext);

  const [columnHeader, setColumnHeader] = useState([]);

  const project_id = localStorage.getItem("project_id");

  useEffect(()=>{
    if(conventionModeData.isSuccess){
      if(!hideItems){
        setConventionalData((prevData) => {
          const newData = [...conventionModeData.response?.data?.line_items];
          return newData;
        });
      }else{
        setConventionalData((prevData) => {
          const filteredData = conventionModeData.response?.data?.line_items.filter(
            (data) => {
              if(data.hasOwnProperty('map_data')){
                if(data.map_data.hasOwnProperty('cy_data')){
                  if(data.map_data.cy_data.sub_grp === "" || data.map_data.cy_data.sub_grp === null){
                    return data
                  }
                }else if (data.map_data.hasOwnProperty('py_data')){
                  if(data.map_data.py_data.sub_grp === "" || data.map_data.py_data.sub_grp === null){
                    return data
                  }
                }
              }else{
                if(data.sub_grp === "" || data.sub_grp === null){
                  return data
                }
            }
            }
            
          );
          return filteredData;
        });
      }
    }
  },[hideItems, conventionModeData.response])

  useEffect(()=>{
    if(filterEnable){
      setConventionalData(conventionModeData.filteredData ?? [])
    }else{
      setConventionalData(conventionModeData.response?.data?.line_items);
    }
  }, [filterEnable, conventionModeData.filteredData])

  useEffect(() => {
    (async () => {
      await getConventionalMode();
      await getNewColumnUpdate();
    })();
  }, [checkYearly]);

  useEffect(() => {
    // if (!nctIsSuccess) {
      dispatch(fetchColumns(project_id))
    // }
  }, [])

  const getConventionalMode = async () => {
    if (
      !conventionModeData.isSuccess ||
      conventionModeData.set_payload !== JSON.stringify({ project_id, tb_id })
    ) {
      dispatch(fetchConventionalMode(project_id, tb_id));
    }
  };

  const mergeInPlace = (a, b, i = 0) => {
    return a.slice(0, i).concat(b, a.slice(i));
  };

  const getNewColumnUpdate = async () => {
    try {
      let a = [...columnBaseTable];
      let b = [];
      let merge = mergeInPlace(a, b, 3);
      if (nctIsSuccess) {
        if (newColumns) {
          setColumnHeader([...merge, ...newColumns]);
        }else{
          setColumnHeader(merge);
        }
      }else{ setColumnHeader(merge); }
    } catch (err) {
      console.error("error", err.message);
    }
  };

  function brackets(val) {
    if (val < 0) {
      return "(" + (-1 * val).toLocaleString("en-IN") + ")";
    }
    return val.toLocaleString("en-IN");
  }

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  let total = 0;
  let finaldata = 0;
  const lenghtdata = conventionalData.length;
  const selectedRows = (data) => {
    total = total + 1;
    finaldata = (total / lenghtdata) * 100;
    props.getdata(Math.round(finaldata));
    // dispatch({ type: actionTypes.SET_PROGRESSBAR_DATA, payload: finaldata });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: columnHeader,
      data: conventionalData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(()=>{
    dispatch(storeDragMultiSelected(selectedFlatRows.map(row=>row.original)))
  }, [selectedFlatRows])

  const handleMap = async (type, row, change, type2) => {
    if(!change)return;
    switch(type){
      case "fs_grp":{
        const mapData = {
          fs_grp: change,
          note_grp: "",
          sub_grp: "",
          note_no: "" 
        }
        let updatedData =  {
          ...row
        }
        if(checkYearly){
          updatedData =  {
            ...updatedData,
            map_data: {
              ...updatedData.map_data,
              [type2+'_map']:{...mapData}
            }
          }
        }else{
          updatedData = {...updatedData, map_data: {
            ...updatedData.map_data,
            cy_map: {...mapData},
            py_map: {...mapData}
          }}
        }
        
        dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
        return true
      }
      case "note_grp":{
        let parseChange = JSON.parse(change);
        const mapData = {
          fs_grp: row?.map_data?.[type2 + '_map']?.fs_grp || row.fs_grp,
          note_grp: parseChange.notes_grp,
          note_no: parseChange.notes_no,
          sub_grp: "",
        }
        let updatedData =  {
          ...row
        }
        if(checkYearly){
          updatedData =  {
            ...updatedData,
            map_data: {
              ...updatedData.map_data,
              [type2+'_map']:{...mapData}
            }
          }
        }else{
          updatedData = {...updatedData, map_data: {
            ...updatedData.map_data,
            cy_map: {...mapData},
            py_map: {...mapData}
          }}
        }
        dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
        return true
      }
      case "sub_grp":{
        const mapData = {
          fs_grp: row?.map_data?.[type2 + '_map']?.fs_grp || row.fs_grp,
          note_grp: row?.map_data?.[type2 + '_map']?.note_grp || row.note_grp,
          note_no: row?.map_data?.[type2 + '_map']?.note_no || row.note_no,
          sub_grp: change
        }
        let updatedData =  {
          ...row,
        }
        if(checkYearly){
          updatedData =  {
            ...updatedData,
            map_data: {
              ...updatedData.map_data,
              [type2+'_map']:{...mapData}
            }
          }
        }else{
          updatedData = {...updatedData, map_data: {
            ...updatedData.map_data,
            cy_map: {...mapData},
            py_map: {...mapData}
          }}
        }
        dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
        return true
      }
      default:
        return false
    }
  }

  const  handleDrag = (cell) => {
    if(selectedFlatRows.length > 0){
      setHighlight(true);
      dispatch(storeDragSelected(selectedFlatRows.map(ele => ele?.original)));
    }else{
      setHighlight(true);
      setSelected(cell.row.original.lid);
      dispatch(storeDragSelected(cell.row.original));
    }
  }

  const handleGroupData = (type, row, mapType) => {
    let {fs_grp, note_grp} = row.original;
    if(row.original.hasOwnProperty('map_data') && row.original.map_data.hasOwnProperty(mapType)){
      fs_grp = row.original.map_data[mapType].fs_grp;
      note_grp = row.original.map_data[mapType].note_grp;
    }
    switch(type){
      case "fs_grp_cy":
        if(groupData.isSuccess){
          return groupDataRes?.fs_grp?.map(fsGrp=> fsGrp.fs_grp)
        }else{
          return []
        }
      case "fs_grp_py":
        if(groupData.isSuccess){
          return groupDataRes?.fs_grp?.map(fsGrp=> fsGrp.fs_grp)
        }else{
          return []
        }
      case "note_grp_cy":
        if(groupData.isSuccess && fs_grp){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
          ?.notes_grp.map(notes => {return {notes_grp: notes.notes_grp, notes_no: notes.notes_no}})
        }else{
          return []
        }
      case "note_grp_py":
        if(groupData.isSuccess && fs_grp){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
          ?.notes_grp.map(notes => {return {notes_grp: notes.notes_grp, notes_no: notes.notes_no}})
        }else{
          return []
        }
      case "sub_grp_cy":
        if(groupData.isSuccess && fs_grp && note_grp){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
          ?.notes_grp.find(note => note.notes_grp === note_grp)?.sub_grp.map(subGrp => subGrp.sub_grp)
        }else{
          return []
        }
      case "sub_grp_py":
        if(groupData.isSuccess && fs_grp && note_grp){
          return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
          ?.notes_grp.find(note => note.notes_grp === note_grp)?.sub_grp.map(subGrp => subGrp.sub_grp)
        }else{
          return []
        }
      default:
        return []
    }
  }

  if (conventionModeData.loading || groupData.loading) {
    return (
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="grow" size="sm" />
        <Spinner animation="grow" style={{ margin: "1rem" }} />
        <Spinner animation="grow" size="sm" />
      </div>
    );
  }
  return (
    <div className={props.template ? "conventionalTab" : "conventionalTable"}>
      <MultiSelect
        setAddGrouping={props.setAddGrouping}
        SetNewSg={props.SetNewSg}
        multiOptions={props.multiOptions}
        fetchConvential={props.fetchConvential}
      />
      <table
        {...getTableProps()}
        style={{
          background: `${theme ? "" : "#363535"}`,
          color: `${theme ? "" : "#fff"}`,
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="font-inter"
                  style={column.Style}
                  {...column.getHeaderProps()}
                >
                 {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, ind) => {
            if (row.original.sub_grp !== "") {
              selectedRows(row.original.sub_grp);
            }
            prepareRow(row);
            return (
              <tr
                onClick={() => prepareRow(row)}
                className={
                  row.isSelected
                    ? "highlightedRow"
                    : (row.original?.map_data?.cy_map && row.original?.map_data?.py_map || row.original)?.sub_grp
                    ? "selectedRow"
                    :(row.original?.map_data?.cy_map || row.original?.map_data?.py_map)?.sub_grp
                    ? "selectedSingleRow"
                    : null
                }
                {...row.getRowProps()}
              >
                {row.cells.map((cell, i) => {
                  if (cell.column.Header === "LEDGER CODE") {
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        {...cell.getCellProps()}
                        style={{ textAlign: "left", paddingLeft:"10px" }}
                      >
                        {cell.row.original.ledger_code}
                      </td>
                    );
                  }
                  if (cell.column.Header === "LEDGER NAME") {
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        {...cell.getCellProps()}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  if (cell.column.Header === "BRANCH") {
                    if (cell.row.original.branch == null) {
                      return (
                        <td
                          draggable="true"
                          onDragStart={() => {
                            handleDrag(cell);
                          }}
                          onDragEnd={() => {
                            setHighlight(false);
                          }}
                          className={
                            highlight && cell.row.original.lid === selected
                              ? "highlighted"
                              : null
                          }
                          style={{ textAlign: "center" }}
                        >
                          -
                        </td>
                      );
                    }
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        {...cell.getCellProps()}
                        style={{ textAlign: "center" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  if (cell.column.Header === "ADJUSTMENT AMT") {
                    if (cell.row.original.adjusted_amount === 0) {
                      return (
                        <td
                          draggable="true"
                          onDragStart={() => {
                            handleDrag(cell);
                          }}
                          onDragEnd={() => {
                            setHighlight(false);
                          }}
                          className={
                            highlight && cell.row.original.lid === selected
                              ? "highlighted"
                              : null
                          }
                          style={{ textAlign: "right", padding: "10px" }}
                        >
                          -
                        </td>
                      );
                    }
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        style={{ textAlign: "right", padding: "10px" }}
                      >
                        {cell.row.original.adjusted_amount}
                      </td>
                    );
                  }
                  if (cell.column.Header === "FINAL AMT") {
                    if (cell.row.original.isAdjusted) {
                      return (
                        <td
                          draggable="true"
                          onDragStart={() => {
                            handleDrag(cell);
                          }}
                          onDragEnd={() => {
                            setHighlight(false);
                          }}
                          className={
                            highlight && cell.row.original.lid === selected
                              ? "highlighted"
                              : null
                          }
                          style={{ textAlign: "right", padding: "10px" }}
                        >
                          {brackets(
                            Number(cell.row.original.cy) +
                              Number(cell.row.original.adjusted_amount)
                          )}
                        </td>
                      );
                    }
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        style={{ textAlign: "right", padding: "10px" }}
                      >
                        <div
                          title={`${brackets(
                            Number(cell.row.original.cy) +
                              Number(cell.row.original.adjusted_amount)
                          )}`}
                          class="visible"
                        >
                          {brackets(
                            Number(cell.row.original.cy) +
                              Number(cell.row.original.adjusted_amount)
                          )}
                        </div>
                      </td>
                    );
                  }                 
                  if (cell.column.Header === "CURRENT YEAR") {
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        {...cell.getCellProps()}
                        style={{ textAlign: "right", paddingRight: "10px" }}
                      >
                        {cell.row.original.cy
                          ? brackets(cell.row.original.cy)
                          : 0}
                      </td>
                    );
                  }
                  if (cell.column.Header === "PREVIOUS YEAR") {
                    return (
                      <td
                        draggable="true"
                        onDragStart={() => {
                          handleDrag(cell);
                        }}
                        onDragEnd={() => {
                          setHighlight(false);
                        }}
                        className={
                          highlight && cell.row.original.lid === selected
                            ? "highlighted"
                            : null
                        }
                        {...cell.getCellProps()}
                        style={{ textAlign: "right", paddingRight: "10px" }}
                      >
                        {cell.row.original.py
                          ? brackets(cell.row.original.py)
                          : 0}
                      </td>
                    );
                  }
                  if (cell.column.Header === "FS GROUPING (CY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                        <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"fs_grp"}
                            options={handleGroupData("fs_grp_cy", row, "cy_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"cy"}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (cell.column.Header === "NOTES GROUPING (CY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                       <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"note_grp"}
                            options={handleGroupData("note_grp_cy", row, "cy_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"cy"}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (cell.column.Header === "SUB GROUPING (CY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                        <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"sub_grp"}
                            options={handleGroupData("sub_grp_cy", row, "cy_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"cy"}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (cell.column.Header === "FS GROUPING (PY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                        <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"fs_grp"}
                            options={handleGroupData("fs_grp_py", row, "py_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"py"}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (cell.column.Header === "NOTES GROUPING (PY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                       <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"note_grp"}
                            options={handleGroupData("note_grp_py", row, "py_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"py"}
                          />
                        </div>
                      </td>
                    );
                  }
                  if (cell.column.Header === "SUB GROUPING (PY)") {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="dropMapping"
                      >
                        <div className="visible">
                          <SelectComponent 
                            rowData={row} 
                            type={"sub_grp"}
                            options={handleGroupData("sub_grp_py", row, "py_map")}
                            handleChange={handleMap}
                            checkYearly={checkYearly}
                            type2={"py"}
                          />
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: "right", paddingRight: "10px" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BasicTable;

export const SelectComponent = (props) => {
  const { rowData, type ,options,  handleChange, checkYearly, type2 } = props;
  const [value, setValue] = useState(null);
  useEffect(()=> {
    { type === 'note_grp' ? 
      setValue(JSON.stringify({notes_grp: getGroupName(rowData.original, type), notes_no: getGroupName(rowData.original, 'note_no')}))
      : setValue(getGroupName(rowData.original, type));
    }
  }, [rowData])

  const getGroupName = (rowData, type) => {
    const checkMap = type2+'_map';
    if(rowData.hasOwnProperty("map_data") && rowData.map_data.hasOwnProperty(checkMap)){
      return rowData.map_data[checkMap][type];
    }else{
      return rowData[type];
    }
  }

  return (
    <select 
      className="form-control dropMapping" 
      value={value}
      onChange={(e)=>{
        setValue(e.target.value); 
        handleChange(type, rowData.original, e.target.value, type2);
      }}
    >
      <option hidden key='blank_choice'>Select an option...</option>
      {options?.map((option)=>(
        <option value={type === 'note_grp' ? JSON.stringify(option) : option}>
          {type === 'note_grp' ? option.notes_grp : option}
        </option>
      ))}
    </select>
  )
}